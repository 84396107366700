import { Button } from "semantic-ui-react";
import "./ButtonComponent.scss";

export interface IButtonComponentProps {
    label: string;
    disabled?: boolean;
    overlay?: string;
}

export const ButtonComponent = (props: IButtonComponentProps) => {

    return (
        <div className="button-component">
            {props?.overlay ?
                <Button
                    disabled={props?.disabled}>
                    <span className="label"
                    >
                        {props.label}
                    </span>
                    <span className="overlay">
                        {props?.overlay}
                    </span>
                </Button>
                :
                <Button disabled={props?.disabled}>
                    {props.label}
                </Button>
            }
        </div>
    );
};