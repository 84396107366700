import { Config } from 'plotly.js';
import { useMemo } from 'react';
import Plot from 'react-plotly.js';
import { Site, TypeIIEcoIndicator } from '../../models/lookups';
import { RiffleDepthViewModel } from '../../services/RiffleDepthService';
import { IPlotLayoutProps } from './helpers/PlotLayoutProps';

export interface IRifflePassageCurveProps extends IPlotLayoutProps {
    riffleDepthViewModel: RiffleDepthViewModel,
    typeIIEcoIndicator: TypeIIEcoIndicator,
    site: Site
}

export const RifflePassageCurve = (props: IRifflePassageCurveProps) => {

    const config: Partial<Config> = {
        toImageButtonOptions: {
            format: 'png',
            filename: `Riffle Passage - ${props.site.siteName} - ${props.typeIIEcoIndicator.species.displayName} - ${props.typeIIEcoIndicator.lifestage.displayName}`,
            scale: 3
        },
        displaylogo: false,
    }

    const notPassableData: {x: number[], y: number[]} = useMemo(() =>{
        var maxThresholdCfs: number = Math.max(...props.riffleDepthViewModel.thresholdDistanceAndFlow.map(x => x.flowCfs));
        var flowRates: number[] = props.riffleDepthViewModel.riffleDepthValuesData.map(x => parseFloat(x.columnName));
        return {
            x: [...flowRates, maxThresholdCfs, maxThresholdCfs].sort((a, b) => a - b),
            y: [...Array(flowRates.filter(x => x <= maxThresholdCfs).length + 1).fill(0), 
                ...Array(flowRates.filter(x => x > maxThresholdCfs).length + 1).fill(1)]
        };
    }, [props.riffleDepthViewModel]) 

    return (
        <>
            {props &&
                <Plot
                    data={[
                        {
                            mode: 'lines+markers',
                            name: "",
                            x: notPassableData.x,
                            y: notPassableData.y,
                            line: {
                                color: 'black',
                             },
                            showlegend: false
                        }
                    ]}
                    layout={{
                        width: props.width,
                        height: props.height,
                        font: {
                            family: 'Arial,Helvetica',
                            size: 12,
                            color: 'black',
                        },
                        xaxis: {
                            title: `<b>Flow rate (cfs)</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true, 
                            type: 'log'
                        },
                        yaxis: {
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true,
                            tickmode: 'array',
                            tickvals: [0, 1],
                            ticktext: ['<b>unpassable</b>', '<b>passable</b>']
                        },
                        plot_bgcolor: 'rgba(0,0,0,0)', // transparent
                        paper_bgcolor: 'rgba(0,0,0,0)', // transparent
                        legend: {
                            font: {
                                family: 'sans-serif',
                                size: 12,
                                color: '#000'
                            },
                            traceorder: 'reversed',
                        },
                    }}
                    config={config}
                />
            }
        </>
    );
};