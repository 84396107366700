import { useContext, useEffect, useMemo, useState } from "react";
import { Icon, Menu, Segment } from "semantic-ui-react";
import { DropdownComponentWithFunction } from "../../dropdowns/Dropdown";
import { SectionA } from "./SectionA";
import { SectionB } from "./SectionB";
import { SectionC } from "./SectionC";
import { Summary } from "./Summary";
import { LookupsContext } from "../../../App";
import { FlowMatrixService, FlowMatrixViewModel, WaterYear } from "../../../services/FlowMatrixService";
import { FunctionalFlowService, FunctionalFlowViewModel } from "../../../services/FunctionalFlowService";
import { EcoIndicator, FlowMetric, Site, TypeIIEcoIndicator } from "../../../models/lookups";
import "./DataTabs.scss";

export interface IDataTabsProps {
    siteId: number;
}

export const DataTabs = (props: IDataTabsProps) => {
    const [lookups] = useContext(LookupsContext);

    const [selectedMetricId, setSelectedMetricId] = useState<number | null>(null); // metric
    const [selectedMetricTypeId, setSelectedMetricTypeId] = useState<number | null>(null); // component
    const [activeItem, setActiveItem] = useState<string>("SectionA");

    const flowMatrixService = useMemo(() => new FlowMatrixService(), []);
    const [flowMatrixViewModel, setFlowMatrixViewModel] = useState<FlowMatrixViewModel | null>(null);
    const [functionalFlowViewModel, setFunctionalFlowViewModel] = useState<FunctionalFlowViewModel | null>(null);

    const [waterYears, setWaterYears] = useState<Array<WaterYear> | null>(null);

    const functionalFlowService = useMemo(() => new FunctionalFlowService(), []);

    // second dropdown
    const selectedFlowMetric: FlowMetric | null = useMemo(() => {
        if (!(selectedMetricId && lookups)) return null;
        return lookups.flowMetrics.find((x) => x.id === selectedMetricId) ?? null;
    }, [selectedMetricId, lookups])

    const selectedSite: Site | null = useMemo(() => {
        if (!(props.siteId && lookups)) return null;
        return lookups.sites.find((x) => x.id === props.siteId) ?? null;
    }, [props.siteId, lookups])

    // get functional flow data
    useEffect(() => {
        let fetchData = async () => {
            let data = await functionalFlowService.getFunctionalFlowDataBySiteId(props.siteId);
            setFunctionalFlowViewModel(data);
        }

        fetchData().catch(console.log);
    }, [functionalFlowService, props.siteId])

    // get flow matrix data
    useEffect(() => {
        let fetchData = async () => {
            let data = await flowMatrixService.getFlowMatrixDataBySiteId(props.siteId);
            setFlowMatrixViewModel(data);
        }

        fetchData().catch(console.log);
    }, [flowMatrixService, props.siteId])

    // get water years
    useEffect(() => {
        let fetchData = async () => {
            let data = await flowMatrixService.getWaterYearsBySiteId(props.siteId);
            setWaterYears(data.sort((a, b) => a.year - b.year));
        }
        fetchData().catch(console.log);
    }, [flowMatrixService, props.siteId])

    // this section sets options for dropdowns and header/button on SectionA tab
    // this sets menu options for Metric Type dropdown
    const flowMetricTypeOptions = useMemo(() => lookups?.flowMetricTypes?.map(f => ({ key: f.id, text: f.displayName, value: f.id })) ?? [], [lookups]);

    // this sets menu options for Metric dropdown
    const flowMetricOptions = useMemo(() => {
        if (!lookups) {
            return [];
        }
        return lookups.flowMetrics.filter(x => x.flowMetricTypeId === selectedMetricTypeId).map((x: FlowMetric) => {
            return {
                key: x.id, text: x.displayName
                , value: x.id
            }
        });
    }, [lookups, selectedMetricTypeId]);


    const displayHeaderName = useMemo(() => {
        return lookups?.flowMetrics?.find(x => x.id === selectedMetricId)?.displayName;
    }, [lookups, selectedMetricId])

    useEffect(() => {
        setSelectedMetricTypeId(flowMetricTypeOptions[0]?.key)
    }, [flowMetricTypeOptions]);

    useEffect(() => {
        setSelectedMetricId(flowMetricOptions[0]?.key)
    }, [selectedMetricTypeId, flowMetricOptions]);

    const [selectedEcoIndicatorId, setSelectedEcoIndicatorId] = useState<number | null>(null);

    const ecoIndicator: EcoIndicator | undefined = useMemo(() => {
        if (!(lookups && selectedEcoIndicatorId && selectedEcoIndicatorId > 10000)) { return } // hacky way to distinguish between EcoIndicator and TypeIIEcoIndicator Ids
        return lookups.ecoIndicators.find(x => x.id === selectedEcoIndicatorId - 10000)
    }, [selectedEcoIndicatorId, lookups])

    const typeIIEcoIndicator: TypeIIEcoIndicator | undefined = useMemo(() => {
        if (!(lookups && selectedEcoIndicatorId && selectedEcoIndicatorId < 10000)) { return }
        return lookups.typeIIEcoIndicators.find(x => x.id === selectedEcoIndicatorId)
    }, [selectedEcoIndicatorId, lookups])

    return (
        <div className="data-tabs">
            <div className="select-metric-wrapper">
                <div className="dropdowns-metric-wrapper">
                    <div className="select-metric">
                        {lookups &&
                            <DropdownComponentWithFunction
                                options={flowMetricTypeOptions}
                                selected={selectedMetricTypeId ?? undefined}
                                label={"Component"}
                                setSelected={setSelectedMetricTypeId} // component
                            />
                        }
                    </div>
                    <div className="select-metric">
                        {lookups &&
                            <DropdownComponentWithFunction
                                options={flowMetricOptions}
                                selected={selectedMetricId ?? undefined}
                                label={"Metric"}
                                setSelected={setSelectedMetricId} // metric
                            />
                        }
                    </div>
                </div>
                <div className="selected-metric">
                    <div className="selected">
                        {selectedFlowMetric ? selectedFlowMetric.displayName : <div><Icon name="arrow up" /> Select Metrics From The Dropdowns <Icon name="arrow up" /></div>}
                    </div>
                </div>
            </div>

            <div className="menu-tab-view-wrapper">
                <Menu attached='top' tabular>
                    <Menu.Item
                        name='SectionA'
                        active={activeItem === 'SectionA'}
                        onClick={() => setActiveItem("SectionA")}
                    />
                    <Menu.Item
                        name='SectionB'
                        active={activeItem === 'SectionB'}
                        onClick={() => setActiveItem("SectionB")}
                    />
                    <Menu.Item
                        name='SectionC'
                        active={activeItem === 'SectionC'}
                        onClick={() => setActiveItem("SectionC")}
                    />
                    <Menu.Item
                        name='Summary'
                        active={activeItem === 'Summary'}
                        onClick={() => setActiveItem("Summary")}
                    />
                </Menu>

                <Segment attached='bottom' className="tab-view">
                    {activeItem === "SectionA" &&
                        <>
                            {lookups && selectedSite &&

                                <SectionA
                                    site={selectedSite}
                                    componentDropdownMetricTypeId={selectedMetricTypeId} // component dropdown metric
                                    metricDropdownMetric={selectedFlowMetric} // metric dropdown metric
                                    metricButtonLabel={displayHeaderName}
                                    waterYears={waterYears}
                                    functionalFlowViewModel={functionalFlowViewModel}
                                    flowMatrixViewModel={flowMatrixViewModel}
                                />
                            }
                        </>
                    }
                    {activeItem === "SectionB" && selectedSite &&
                        <SectionB
                            site={selectedSite}
                            metric={selectedFlowMetric}
                            setSelectedEcoIndicatorId={setSelectedEcoIndicatorId}
                            selectedEcoIndicatorId={selectedEcoIndicatorId}
                            typeIIEcoIndicator={typeIIEcoIndicator}
                        />
                    }
                    {activeItem === "SectionC" && selectedSite &&
                        <SectionC
                            site={selectedSite}
                            metric={selectedFlowMetric}
                            ecoIndicator={ecoIndicator}
                            selectedEcoIndicatorIdSectionB={selectedEcoIndicatorId}
                            typeIIEcoIndicator={typeIIEcoIndicator}
                        />
                    }
                    {activeItem === "Summary" &&
                        <Summary />
                    }
                </Segment>
            </div>

        </div>
    );
};
