import { useContext } from "react";
import { Segment, Sidebar } from "semantic-ui-react";
import { Tab } from 'semantic-ui-react'
import { LookupsContext } from "../../App";
import hexdrop from "../../images/hex_drop.svg"
import "./LeftIntroSidebar.scss";

export interface ILeftIntroSidebarProps {
    visible: boolean;
    handleMapClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const LeftIntroSidebar = (props: ILeftIntroSidebarProps) => {
    const [lookups] = useContext(LookupsContext);

    const futureSites = [
        "SFE_2248 Michaels Creek",
        "NC_CH_2_19608 Salt Cr (NF Mad River)",
        "NC_CH_2_2840 Elk River",
        "NC_UL_1_28623 Fox Creek (Van Duzen R)",
        "NC_PH_5_15711 Hely Creek (lower Van Duzen)",
        "NC_CL_1_19561 Panther Creek",
        "SCC_UL_3_29822 Pancho Rico",
        "SCC_CL_2_36826 San Jose Creek",
        "SCC_PL_2_42171 Aptos Creek",
        "SCC_CL_5_36425 San Clemente Creek",
        "SCC_CL_5_42228 Aptos Creek",
        "SCC_CL_5_42130 Aptos Creek",
        "SCC_CL_5_36787 San Jose Creek",
        "SCC_CL_2_56438 Williams Creek",
        "SCC_PH_1_40968 San Lorenzo River",
        "SCC_PL_5_35871 Carmel River",
        "SC_UL_4_13094 Santa Ynez trib",
        "SC_PH_1_17267 Matilija",
        "SC_PL_4_45111 San Juan Creek",
        "SC_CL_2_44561 Hicks Creek",
        "SC_CH_4_15972 Arroyo Hondo",
        "SC_PL_1_28522 Las Virgenes"
    ];

    const panes = [
        {
            menuItem: 'Available Sites', render: () =>
                <Tab.Pane>
                    {lookups?.sites.map((site, index) =>
                        <div
                            key={index}
                            className="label"
                            site-id={site.id}
                            onClick={(e) => props.handleMapClick(e)}
                        >
                            {site.siteName}
                        </div>
                    )}
                </Tab.Pane>
        },
        {
            menuItem: 'Future Sites', render: () =>
                <Tab.Pane>
                    {futureSites.map((site, index) =>
                        <div
                            key={index}
                            className="label"
                        >
                            {site}
                        </div>
                    )}
                </Tab.Pane>
        }
    ]


    return (
        <Sidebar
            className="intro-left-sidebar"
            as={Segment}
            animation='overlay'
            direction='left'
            vertical
            visible={props?.visible}
            width='wide'
        >
            <div className="intro-left-sidebar-wrapper">
                <div className="intro-left-header">
                    <div className="intro-left-header-frame">
                        California Environmental Flows Framework
                    </div>
                    <div className="hex-drop">
                        <img src={hexdrop} alt="water drop" />
                    </div>
                </div>
                <div className="intro-left-body">
                    <div className="intro-text">
                        The State Water Board, Division of Water Rights intends to use the California Environmental Flows Framework (CEFF) to develop flow criteria and diversion schedules for streams in the North Coast Policy Area. The process and workflow developed in the North Coast is intended to serve as a model for similar efforts in policy areas throughout California.
                    </div>
                    <div className="intro-tabs">
                        <Tab
                            className="intro-left-tabs"
                            panes={panes}
                        />
                    </div>
                </div>
            </div>
        </Sidebar>
    );
};