import { useEffect, useState } from "react";
import { Sidebar, Segment, Divider, Popup, Icon } from "semantic-ui-react";
import { ButtonComponent } from "../buttonComponent/ButtonComponent";
import { Site } from "../../models/lookups";
import "./LeftSidebar.scss";

export interface ILeftSidebarProps {
    visible: boolean;
    setVisible: Function;
    setVisibleIntro: Function;
    lookups: any;
    selectedId: number | undefined;
    setSelectedId: Function;
    site: Site | undefined;
}

export const LeftSidebar = (props: ILeftSidebarProps) => {

    const [image, setImage] = useState<string | null>(null)

    useEffect(() => {
        if (!props.site) {
            return;
        }

        try {
            const src = require(`../../images/siteImages/${props.site.id}.png`)
            setImage(src);
        }
        catch (_) {
            setImage(null);
        }
    }, [props.site])

    const backClick = () => {
        props?.setVisible(false);
        props?.setVisibleIntro(true);
    }

    return (
        <Sidebar
            className="left-sidebar-data"
            as={Segment}
            animation='overlay'
            direction='left'
            vertical
            visible={props?.visible}
            width='wide'
        >
            <div className="left-sidebar-wrapper">
                <div className="left-sidebar-header">
                    <div className="left-header-frame">
                        <div className="left-header-icon">
                            <Icon name="arrow left" onClick={backClick} />
                        </div>
                        <div className="left-header-text">
                            California Environmental Flows Framework
                        </div>
                    </div>
                </div>
                <div className="left-body">
                    <div className="left-sidebar-title">{props?.site?.siteName}</div>
                    <Divider />
                    <div className="selected-site-info">
                        <div className="site-info">
                            <div className="site-info-key">
                                LOI:
                            </div>
                            <div className="site-info-value">
                                {props?.site?.loi}
                            </div>
                        </div>
                        <div className="site-info">
                            <div className="site-info-key">
                                Latitude:
                            </div>
                            <div className="site-info-value">
                                {props?.site?.latitude}
                            </div>
                        </div>
                        <div className="site-info">
                            <div className="site-info-key">
                                Longitude:
                            </div>
                            <div className="site-info-value">
                                {props?.site?.longitude}
                            </div>
                        </div>
                        <div className="site-info">
                            <div className="site-info-key">
                                Contributing Area:
                            </div>
                            <div className="site-info-value">
                                {props?.site?.contributingAreaSqMi.toFixed(2)} mi<sup>2</sup>
                            </div>
                        </div>

                        {/* These might be used in the future so leaving them in per Johnny */}
                        {/* <div className="site-info">
                        <div className="site-info-key">Geomorphic Class: </div>
                        <div className="site-info-value">{site?.geomorphicClass.displayName}</div>
                       </div>
                       <div className="site-info">
                        <div className="site-info-key">Geologic Setting: </div>
                        <div className="site-info-value">{site?.geologicSetting.displayName}</div>
                    </div> */}
                        <div className="site-info">
                            <div className="site-info-key">
                                Mean Annual Flow: </div>
                            <div className="site-info-value">
                                {props?.site?.meanAnualFlowCfs.toFixed(2)} cfs
                            </div>
                        </div>
                        <div className="site-info">
                            <div className="site-info-key">
                                Slope: </div>
                            <div className="site-info-value">
                                {props?.site?.slope}
                            </div>
                        </div>
                        <div className="site-info">
                            <div className="site-info-key">
                                Bankfull Depth: </div>
                            <div className="site-info-value">
                                {props?.site?.bankfullDepthFt.toFixed(2)} ft
                            </div>
                        </div>
                        <div className="site-info">
                            <div className="site-info-key">
                                Bankfull Width: </div>
                            <div className="site-info-value">
                                {props?.site?.bankfullWidthFt.toFixed(2)} ft
                            </div>
                        </div>
                        <div className="site-info">
                            <div className="site-info-key">
                                D<sub>50</sub>:
                            </div>
                            <div className="site-info-value">
                                {props?.site?.d50GrainSizeMm.toFixed(2)} mm
                            </div>
                        </div>
                        <div className="site-info">
                            <div className="site-info-key">D<sub>84</sub>:
                            </div>
                            <div className="site-info-value">
                                {props?.site?.d84GrainSizeMm.toFixed(2)} mm
                            </div>
                        </div>
                        <div className="site-info">
                            <div className="site-info-key">D<sub>max</sub>:
                            </div>
                            <div className="site-info-value">
                                {props?.site?.dMaxGrainSizeMm.toFixed(2)} mm
                            </div>
                        </div>

                    </div>
                    <div className="sidebar-site-buttons">
                        <ButtonComponent label="Edit/View Channel" overlay="COMING SOON" />
                        <ButtonComponent label="Topography" overlay="COMING SOON" />
                    </div>
                    {/* will be adding popup on hover for site image - previously added scaling/expanding on hover makes all other parts of sidebar jump */}
                    {image &&
                        <div className="sidebar-image-wrapper">
                            <div className="site-preview-header">Site Image Preview</div>
                            <Popup style={{ borderRadius: 10, padding: '1rem 1rem 0.6rem' }} className="site-preview-popup" content={<img src={image} style={{ width: "40rem" }} alt="site" />} trigger={<img src={image} alt="site" />} position='top right' />
                        </div>
                    }
                </div>
            </div>
        </Sidebar>
    );
};
