import { Image } from "semantic-ui-react";
import logo1 from "../../images/hex_drop.png"
import logo2 from "../../images/hex_drop.png"
import logo3 from "../../images/hex_drop.png"
import "./ResourcePage.scss";

export const ResourcePage = (props: any) => {

    return (
        <div className="resource">
            <div className="welcome-links-subtitle">
                Links to Helpful Resources
            </div>
            <div className="welcome-links">
                <a href="https://rivers.codefornature.org/" target="_blank" rel="noreferrer">
                    <div className="link">
                        <div className="link-image">
                            <Image src={logo1} />
                        </div>
                        <div className="divider">&nbsp;</div>
                        <div className="link-text">
                            <div className="link-text-header">
                                California Natural Flows Database
                            </div>
                            <div className="link-text-body">
                                Explore, visualize, and download the natural flows data using an intuitive graphical user interface.
                            </div>
                        </div>
                    </div>
                </a>
                <a href="https://ceff.ucdavis.edu/" target="_blank" rel="noreferrer">

                    <div className="link">
                        <div className="link-image">
                            <Image src={logo2} />
                        </div>
                        <div className="divider">&nbsp;</div>
                        <div className="link-text">
                            <div className="link-text-header">
                                CA Environmental Flows Framework
                            </div>
                            <div className="link-text-body">
                                Provides guidance to efficiently develop scientifically defensible environmental recommendations.
                            </div>
                        </div>
                    </div>
                </a>
                <a href="https://eflows.ucdavis.edu/" target="_blank" rel="noreferrer">
                    <div className="link">
                        <div className="link-image">
                            <Image src={logo3} />
                        </div>
                        <div className="divider">&nbsp;</div>
                        <div className="link-text">
                            <div className="link-text-header">
                                eFlows Database<br />Beta-v2.32
                            </div>
                            <div className="link-text-body">
                                Explore and visualize hydrology, geomorphology, and ecology using data from California.
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}
