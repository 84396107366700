import "./VersionNumber.scss"

export interface IVersionNumberProps {
    version: string,
    environment?: string
}

export function VersionNumber(props: IVersionNumberProps) {

    return (
        <div className="version-number-wrapper">
            {props.version} {props.environment && `(${props.environment})`}
        </div>
    )
}
