import { Dropdown } from "semantic-ui-react";
import "./Dropdown.scss";

export interface IDropdownProps {
    options: Array<IDropdownOption>;
    placeholder?: string;
    selected?: number | string;
    setSelected?: Function;
    label?: string;
}

export interface IDropdownOption {
    key: number | string;
    text: number | string;
    value: number | string;
}

export const DropdownComponentWithFunction = (props: IDropdownProps) => {

    return (
        <div className="dropdown-wrapper">
            {props.label &&
                <div className="dropdown-label">
                    {props.label}
                </div>
            }
            <Dropdown
                className="dropdown"
                placeholder={props.placeholder}
                fluid
                selection
                options={props.options}
                value={props.selected}
                onChange={(e, d) => props.setSelected ? props.setSelected(d.value) : null}
            />
        </div>
    );
};