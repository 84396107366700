import { useEffect, useMemo, useState } from 'react';
import { Site, FlowMetric } from '../../../models/lookups';
import { FlowMatrixViewModel, WaterYear } from "../../../services/FlowMatrixService";
import { FunctionalFlowViewModel } from '../../../services/FunctionalFlowService';
import { ButtonGroupComponent } from '../../buttonGroupComponent/ButtonGroupComponent';
import { AnnualStatisticalHeatmap } from "../../graphs/AnnualStatisticalHeatmap";
import { AnnualStatisticalHydrograph } from '../../graphs/AnnualStatisticalHydrograph';
import { WaterYearBoxPlot } from '../../graphs/WaterYearBoxPlot';
import { WaterYearLineGraph } from '../../graphs/WaterYearLineGraph';
import { useDimensions } from './UseDimensions';
import { FlowMetricTable } from './FlowMetricTable';
import "./SectionA.scss";

export interface IDataTabSectionProps {
    site: Site,
    componentDropdownMetricTypeId?: number | null;
    metricDropdownMetric?: FlowMetric | null,
    metricButtonLabel?: string | null,
}

export interface ISectionAProps extends IDataTabSectionProps {
    waterYears: Array<WaterYear> | null
    functionalFlowViewModel: FunctionalFlowViewModel | null,
    flowMatrixViewModel: FlowMatrixViewModel | null,
}

export const SectionA = (props: ISectionAProps) => {
    const [activeItem, setActiveItem] = useState<string>("Natural Flow Hydrographs");
    const [divRef, dimensions] = useDimensions();

    const chartWidth = useMemo(() => (dimensions?.width ?? 0) > 1200 ? (dimensions?.width ?? 0) / 2.1 : dimensions?.width ?? 0, [dimensions])

    const chartWidthForTable = useMemo(() => (dimensions?.width ?? 0) > 1200 ? (dimensions?.width ?? 0) / 2 : dimensions?.width ?? 0, [dimensions])

    useEffect(() => {
        setActiveItem("Natural Flow Hydrographs");
    }, [props?.componentDropdownMetricTypeId]);

    useEffect(() => {
        if (activeItem !== "Natural Flow Hydrographs") {
            setActiveItem(`Natural ${props?.metricButtonLabel}`);
        }
    }, [props?.metricDropdownMetric, props?.metricButtonLabel, activeItem]);

    return (
        <div className="sectionA">
            <div className="sectionA-button-group-wrapper">
                <div className="sectionA-button-group">
                    <ButtonGroupComponent
                        labels={["Natural Flow Hydrographs", `Natural ${props?.metricButtonLabel}`]}
                        activeItem={activeItem}
                        setActiveItem={setActiveItem} />
                </div>
            </div>
            <div className="sectionA-charts-wrapper" ref={divRef}>
                {props && props.waterYears && props.functionalFlowViewModel && props.flowMatrixViewModel &&
                    <>
                        {activeItem === "Natural Flow Hydrographs" &&
                            <>
                                <div className="charts-table-wrapper">
                                    <div className='outer-chart-wrapper'>
                                        <div className="chart-wrapper">
                                            <div className="chart-header">
                                                Annual Statistical Hydrographs
                                            </div>
                                            <div className="chart">
                                                <AnnualStatisticalHydrograph
                                                    flowMatrixViewModel={props.flowMatrixViewModel}
                                                    site={props.site}
                                                    width={chartWidth}
                                                    height={385}
                                                />
                                            </div>
                                        </div>
                                        <div className="chart-wrapper">
                                            <div className="chart-header">
                                                Annual Statistical Heat Map
                                            </div>
                                            <div className="chart">
                                                <AnnualStatisticalHeatmap
                                                    flowMatrixViewModel={props.flowMatrixViewModel}
                                                    site={props.site}
                                                    width={chartWidth}
                                                    height={385}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {activeItem === `Natural ${props?.metricButtonLabel}` &&
                            <>
                                <div className="charts-table-wrapper">
                                    <div className='outer-chart-wrapper-w-table'>
                                        <div className="chart-wrapper">
                                            <div className="chart-header">
                                                Natural {props?.metricButtonLabel}
                                                <br /><span>(Box Plot)</span>
                                            </div>
                                            <div className="chart-alternative">
                                                {props.metricDropdownMetric &&
                                                    <WaterYearBoxPlot
                                                        metric={props.metricDropdownMetric}
                                                        waterYears={props.waterYears}
                                                        functionalFlowViewModel={props.functionalFlowViewModel}
                                                        width={chartWidthForTable}
                                                        height={400}
                                                    />
                                                }
                                                {!props.metricDropdownMetric &&
                                                    <div className="missing-chart-text-wrapper">
                                                        <div className="missing-chart-text"> Please select a metric above to show this graph</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="chart-wrapper" >
                                            <div className="chart-header">
                                                Natural {props?.metricButtonLabel}
                                                <br /><span>(Time Series)</span>
                                            </div>
                                            <div className="chart-alternative">
                                                {props.metricDropdownMetric &&
                                                    <WaterYearLineGraph
                                                        metric={props.metricDropdownMetric}
                                                        waterYears={props.waterYears}
                                                        functionalFlowViewModel={props.functionalFlowViewModel}
                                                        width={chartWidthForTable}
                                                        height={400}
                                                    />
                                                }
                                                {!props.metricDropdownMetric &&
                                                    <div className="missing-chart-text-wrapper">

                                                        <div className="missing-chart-text"> Please select a metric above to show this graph</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <FlowMetricTable
                                        functionalFlowViewModel={props.functionalFlowViewModel}
                                    />
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </div >
    );
};
