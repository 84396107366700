import "./AboutPage.scss";

export const AboutPage = () => {
    return (
        <>
            <div className="about">
                ABOUT PAGE
            </div>
        </>
    );
};
