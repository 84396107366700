import { useContext, useEffect, useMemo, useState } from 'react'
import Map from "@paradigmh2o/react-map";
import MapConfig from "./MapConfig";
import { DataTabs } from "./tabs/DataTabs";
import { Icon, Segment, Sidebar } from "semantic-ui-react";
import { LookupsContext } from '../../App';
import { Site } from '../../models/lookups';
import { LeftSidebar } from '../leftSidebar/LeftSidebar';
import { LeftIntroSidebar } from '../leftIntroSidebar/LeftIntroSidebar';

import "./DataPage.scss";

export interface IDataPageProps {
}

var distance = function (p1: { id?: number, longitude: number, latitude: number }, p2: { id?: number, longitude: number, latitude: number }) {
    var diffY = p1.latitude - p2.latitude;
    var diffX = p1.longitude - p2.longitude;
    return Math.sqrt((diffY * diffY) + (diffX * diffX));
}

export interface ISiteLocation {
    id?: number;
    latitude: number;
    longitude: number;
}

export const DataPage = (props: IDataPageProps) => {
    const [lookups] = useContext(LookupsContext);

    const [visibleIntro, setVisibleIntro] = useState(true);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedId, setSelectedId] = useState<number | undefined>(undefined);

    const handleSidebarSiteClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setSelectedId(parseInt((e.currentTarget as HTMLInputElement).getAttribute("site-id") ?? ""))
        setVisibleIntro(!visibleIntro);
        setVisible(!visible);
    }

    const handleMapClick = (e: any) => {
        // For now it just checks for the closest site to the click (like a voronoi diagram)
        var clickedPoint: ISiteLocation = { longitude: e.mapPoint.longitude, latitude: e.mapPoint.latitude };
        var closest = lookups?.sites
            .map(x => x as ISiteLocation)
            .reduce((acc, next) => distance(clickedPoint, next) < distance(clickedPoint, acc) ? next : acc);
        if (closest) {
            setSelectedId(closest.id);
            setVisibleIntro(!visibleIntro);
            setVisible(!visible);
        }
    }

    const site: Site | undefined = useMemo(() => {
        if (!(lookups && selectedId)) { return }
        return lookups.sites.find(x => x.id === (selectedId))
    }, [selectedId, lookups])

    const closeRightSidebar = () => {
        setVisible(false);
        setVisibleIntro(true);
    }

    useEffect(() => {
        if (lookups) {
            setLoading(false);
        }
    }, [lookups]);

    const onMapViewCreated = (obj: any) => { };

    return (
        <>
            <div className="data-page">
                <Sidebar.Pushable as={Segment}>
                    {/* LEFT INTRO SIDEBAR */}
                    <LeftIntroSidebar
                        visible={visibleIntro}
                        handleMapClick={(e) => handleSidebarSiteClick(e)}
                    />
                    {/* LEFT SIDEBAR */}
                    <LeftSidebar
                        visible={visible}
                        setVisible={setVisible}
                        setVisibleIntro={setVisibleIntro}
                        lookups={lookups}
                        selectedId={selectedId}
                        setSelectedId={setSelectedId}
                        site={site}
                    />

                    {/* RIGHT SIDEBAR */}
                    <Sidebar
                        loading={loading}
                        as={Segment}
                        animation='overlay'
                        direction='right'
                        vertical
                        visible={visible}
                        width='very wide'
                    >
                        <div className="right-sidebar-outer-wrapper">
                            <div className="x-close">
                                <Icon name="close" onClick={closeRightSidebar} />
                            </div>
                            {selectedId &&
                                <DataTabs siteId={selectedId} />
                            }
                        </div>
                    </Sidebar>

                    <Sidebar.Pusher>
                        <div className="map-wrapper">
                            <Segment>
                                <div className="map">
                                    <Map onMapViewCreated={onMapViewCreated} onClick={(e) => handleMapClick(e)} {...MapConfig} />
                                </div>
                            </Segment>
                        </div>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </div>
            <div className="data-too-small">
                PLEASE VISIT THIS PAGE FROM A LARGER DEVICE
            </div>
        </>
    )
}
