import { Config } from 'plotly.js';
import Plot from 'react-plotly.js';
import { EcoIndicator, FlowMetric } from '../../models/lookups';
import { FlowEcoRelationshipViewModel } from '../../services/FlowEcoRelationshipService';
import { IPlotLayoutProps } from './helpers/PlotLayoutProps';

export interface IFlowEcoRelationshipLineGraphProps extends IPlotLayoutProps {
    flowEcoRelationshipViewModel: FlowEcoRelationshipViewModel,
    flowMetric: FlowMetric,
    ecoIndicator: EcoIndicator
}

export const FlowEcoRelationshipLineGraph = (props: IFlowEcoRelationshipLineGraphProps) => {

    const config: Partial<Config> = {
        toImageButtonOptions: {
            format: 'png',
            filename: `Flow Eco Relationship Graph - ${props.ecoIndicator.displayName} - ${props.flowMetric.displayName}`,
            scale: 3
        },
        displaylogo: false,
    }

    return (
        <>
            {props &&
                <Plot
                    data={[{
                        x: props.flowEcoRelationshipViewModel.flowEcoData.map((x) => x.metricValue),
                        y: props.flowEcoRelationshipViewModel.flowEcoData.map((x) => x.goodEcoProbability),
                        marker: {
                            color: "black",
                            size: 0
                        },
                        line: {
                            width: 2,
                        },
                        showlegend: true,
                    }]}
                    layout={{
                        width: props.width,
                        height: props.height,
                        font: {
                            family: 'Arial,Helvetica',
                            size: 12,
                            color: 'black',
                        },
                        xaxis: {
                            title: `<b>Δ ${props.flowMetric.displayName}</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true
                        },
                        yaxis: {
                            title: `<b>Probability of Good ${props.ecoIndicator.displayName}</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true,
                        },
                        plot_bgcolor: 'rgba(0,0,0,0)', // transparent
                        paper_bgcolor: 'rgba(0,0,0,0)', // transparent
                        hovermode: 'x unified',
                        legend: {
                            font: {
                                family: 'sans-serif',
                                size: 12,
                                color: '#000'
                            },
                            traceorder: 'reversed',
                        },
                    }}
                    config={config}
                />
            }
        </>
    );
};