import axios from "axios";
import Config from "../config/Config";
import { Lookups } from "../models/lookups";

export class LookupsService {
    //private authToken: string;

    // constructor(authToken: string) {
    //     this.authToken = authToken;
    // }

    async getLookups(): Promise<Lookups> {
        // let config: AxiosRequestConfig = {
        //     headers: {
        //         "Authorization": "Bearer " + this.authToken
        //     }
        // };
        const result = await axios.get<Lookups>(Config.apiEndpoint + "/Lookups" /*, config*/);

        return result.data;
    }
}
