import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";
import { DiversionYear } from "../models/DiversionYear";

export class TypeIEcoAlterationRiskService {
    private authToken: string;

    constructor(authToken: string) {
        this.authToken = authToken;
    }

    async getTypeIEcoAlterationRisk(siteId: number, flowMetricId: number, ecoIndicatorId: number): Promise<DiversionYear[]> {
        const config: AxiosRequestConfig = {
            headers: {
                "Authorization": "Bearer " + this.authToken
            },
            params: {
                siteId,
                flowMetricId,
                ecoIndicatorId
            }
        };

        const url = `${Config.apiEndpoint}/TypeIEcoAlterationRisk`;

        const result = await axios.get<DiversionYear[]>(url, config);

        return result.data;
    }
}
