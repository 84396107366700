import { Config, Data } from 'plotly.js';
import { useMemo } from 'react';
import Plot from 'react-plotly.js';
import { Site } from '../../models/lookups';
import { FlowMatrixViewModel, FlowMatrixYear } from "../../services/FlowMatrixService";
import { IPlotLayoutProps } from './helpers/PlotLayoutProps';

export interface IAnnualStatisticalHydrographProps extends IPlotLayoutProps {
    site: Site,
    flowMatrixViewModel: FlowMatrixViewModel,
}

export const AnnualStatisticalHydrograph = (props: IAnnualStatisticalHydrographProps) => {

    const config: Partial<Config> = {
        toImageButtonOptions: {
            format: 'png',
            filename: `Annual Statistical Hydrograph - ${props.site.siteName}`,
            scale: 3
        },
        displaylogo: false,
    }

    const quantile = (arr: Array<number>, q: number) => { // calculate quantile of an array
        const sorted = arr.sort((a, b) => a - b);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
            return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
            return sorted[base];
        }
    };

    //Get data from a list of days in a year to a list of days of years
    const transposedData: Array<Array<number>> = useMemo(() => {
        let arrays: Array<Array<number>> = new Array<Array<number>>();
        props.flowMatrixViewModel.flowMatrixYears.forEach((yr: FlowMatrixYear) => {
            arrays.push(yr.flowMatrixDays.map((x) => x.value));
        });

        return arrays.reduce<number[][]>((prev, next) => next.map((item, i) =>
            (prev[i] || []).concat(next[i])
        ), []);

    }, [props.flowMatrixViewModel])


    const makeGraphData: Data[] = useMemo(() => {

        const hydquants = [0.1, 0.25, 0.5, 0.75, 0.9, 1.0]; // quantiles to plot

        const blues = ['rgb(247, 251, 255)',
            'rgb(214, 230, 244)',
            'rgb(171, 208, 230)',
            'rgb(107, 174, 214)',
            'rgb(55, 135, 192)',
            'rgb(16, 92, 164)',
            'rgb(8, 48, 107)']; // colorscale for annual hydrograph stats

        const cdict: any = {
            1.0: [blues[2], blues[1], 'maximum'],
            0.9: [blues[3], blues[2], '90%'],
            0.75: [blues[4], blues[3], '75%'],
            0.5: [blues[5], blues[3], '50%'],
            0.25: [blues[4], blues[2], '25%'],
            0.1: [blues[3], null, '10%']
        };

        let data: Array<Data> = new Array<Data>();
        for (let i = 0; i < hydquants.length; i++) {
            let q = hydquants[i]
            data = data.concat([{
                mode: 'lines',
                x: Array.from({ length: 366 }, (_, i) => i + 1),
                y: transposedData.map(function (value, index) { return quantile(value, q); }),
                name: cdict[q][2],
                line: {
                    width: 1.5,
                    color: cdict[q][0],
                },
                fillcolor: cdict[q][1],
                fill: q > 0.1 ? 'tonexty' : 'none',
                hovertemplate: '%{y:.1f} cfs',
            }]);
        };
        return data
    }, [transposedData]);

    return (
        <>
            {props &&
                <Plot
                    data={makeGraphData}
                    layout={{
                        width: props.width,
                        height: props.height,
                        font: {
                            family: 'Arial,Helvetica',
                            size: 12,
                            color: 'black',
                        },
                        xaxis: {
                            title: `<b>Day of Water Year</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true
                        },
                        yaxis: {
                            title: `<b>Flow rate (cfs)</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true,
                        },
                        plot_bgcolor: 'rgba(0,0,0,0)', // transparent
                        paper_bgcolor: 'rgba(0,0,0,0)', // transparent
                        hovermode: 'x unified',
                        legend: {
                            font: {
                                family: 'sans-serif',
                                size: 12,
                                color: '#000'
                            },
                            traceorder: 'reversed',
                        },
                    }}
                    config={config}
                />
            }
        </>
    );
};