const environment = process.env.REACT_APP_ENVIRONMENT ?? "local";

interface IAuthInfo {
    server: string,
    redirectUri: string,
    scopes: string,
    clientId: string,
}

interface IEnvironment {
    environment: string,
    apiEndpoint: string,
    authInfo: IAuthInfo
}

interface IEnvironments {
    local: IEnvironment,
    dev: IEnvironment,
    qa: IEnvironment,
    prod: IEnvironment,
}

// Overwrite specific config variables per environment here
const environmentSpecific: IEnvironments = {
    local: {
        environment: "local",
        apiEndpoint: "https://localhost:44357",
        authInfo: {
            clientId: "cannabis-api",
            server: "https://localhost:7017",
            redirectUri: "http://localhost:3000/",
            scopes: "roles email profile openid cannabis-api"
        }
    },
    dev: {
        environment: "dev",
        apiEndpoint: "https://api-dev.ceffoutputviewer.org",
        authInfo: {
            clientId: "cannabis-api",
            server: "https://auth-dev.ceffoutputviewer.org",
            redirectUri: "https://dev.ceffoutputviewer.org/",
            scopes: "roles email profile openid cannabis-api"
        }
    },
    qa: {
        environment: "qa",
        apiEndpoint: "https://api-qa.ceffoutputviewer.org",
        authInfo: {
            clientId: "cannabis-api",
            server: "https://auth-qa.ceffoutputviewer.org",
            redirectUri: "https://qa.ceffoutputviewer.org/",
            scopes: "roles email profile openid cannabis-api"
        }
    },
    prod: {
        environment: "prod",
        apiEndpoint: "https://api.ceffoutputviewer.org",
        authInfo: {
            clientId: "cannabis-api",
            server: "https://auth.ceffoutputviewer.org",
            redirectUri: "https://www.ceffoutputviewer.org/",
            scopes: "roles email profile openid cannabis-api"
        }
    },
}

// Default properies can go in here
export let Config = {
    ...environmentSpecific[environment as keyof IEnvironments],
    version: "0.4.0"
}

export default Config;
