import { useContext, useEffect, useMemo, useState } from "react";
import { Divider } from "semantic-ui-react";
import { LookupsContext } from "../../../App";
import { FlowMetric, Site, TypeIIEcoIndicator } from "../../../models/lookups";
import { FlowHabitatAreaService, FlowHabitatAreaViewModel } from "../../../services/FlowHabitatAreaService";
import { HabitatSuitabilityService, HabitatSuitabilityViewModel } from "../../../services/HabitatSuitabilityService";
import { DropdownComponentWithFunction, IDropdownOption } from "../../dropdowns/Dropdown";
import { FlowHabitatAreaCurve } from "../../graphs/FlowHabitatAreaCurve";
import { HabitatSuitabilityCurve } from "../../graphs/HabitatSuitabilityCurve";
import { IDataTabSectionProps } from "./SectionA";
import { useDimensions } from "./UseDimensions";
import { RiffleDepthService, RiffleDepthViewModel } from "../../../services/RiffleDepthService";
import { RiffleDepthCurve } from "../../graphs/RiffleDepthCurve";
import { RifflePassageCurve } from "../../graphs/RifflePassageCurve";
import diagram from "../../../images/concept_diagram.png";
import "./SectionB.scss";

export interface ISectionBProps extends IDataTabSectionProps {
    site: Site,
    metric: FlowMetric | null,
    selectedEcoIndicatorId: number | null;
    setSelectedEcoIndicatorId: Function;
    typeIIEcoIndicator: TypeIIEcoIndicator | undefined;
}

export const SectionB = (props: ISectionBProps) => {
    const [lookups] = useContext(LookupsContext);

    const [divRef, dimensions] = useDimensions();
    const chartWidth = useMemo(() => (dimensions?.width ?? 0) > 1200 ? (dimensions?.width ?? 0) / 2 : dimensions?.width ?? 0, [dimensions])

    const flowHabitatAreaService = useMemo(() => new FlowHabitatAreaService(), []);
    const [flowHabitatAreaViewModel, setFlowHabitatAreaViewModel] = useState<FlowHabitatAreaViewModel | null>(null);
    const [flowHabitatAreaDoesNotExist, setFlowHabitatAreaDoesNotExist] = useState<boolean>(false);

    // get flow habitat area data
    useEffect(() => {
        setFlowHabitatAreaViewModel(null);
        setFlowHabitatAreaDoesNotExist(false);

        if (!(props.typeIIEcoIndicator && props.typeIIEcoIndicator.ecoNeed.internalName === "habitatArea")) {
            return;
        }

        (async () => {
            try {
                let data = await flowHabitatAreaService.getFlowHabitatAreaData(props.site.id, props.typeIIEcoIndicator!.species.id, props.typeIIEcoIndicator!.lifestage.id);
                setFlowHabitatAreaViewModel(data);
                setFlowHabitatAreaDoesNotExist(false);
            }
            catch (response: any) {
                if (response.response.status === 404) {
                    setFlowHabitatAreaDoesNotExist(true);
                }
            }
        })()
    }, [props.site, flowHabitatAreaService, props.typeIIEcoIndicator]);

    const habitatSuitabilityService = useMemo(() => new HabitatSuitabilityService(), []);
    const [habitatSuitabilityViewModel, setHabitatSuitabilityViewModel] = useState<HabitatSuitabilityViewModel | null>(null);
    const [habitatSuitabilityDoesNotExist, setHabitatSuitabilityDoesNotExist] = useState<boolean>(false);

    // get habitat suitability data
    useEffect(() => {
        setHabitatSuitabilityViewModel(null);
        setHabitatSuitabilityDoesNotExist(false);

        if (!(props.typeIIEcoIndicator && props.typeIIEcoIndicator.ecoNeed.internalName === "habitatArea")) {
            return;
        }

        (async () => {
            try {
                let data = await habitatSuitabilityService.getHabitatSuitabilityData(props.typeIIEcoIndicator!.species.id, props.typeIIEcoIndicator!.lifestage.id);
                setHabitatSuitabilityViewModel(data);
                setHabitatSuitabilityDoesNotExist(false);
            }
            catch (response: any) {
                if (response.response.status === 404) {
                    setHabitatSuitabilityDoesNotExist(true);
                }
            }
        })()
    }, [habitatSuitabilityService, props.typeIIEcoIndicator]);

    const riffleDepthService = useMemo(() => new RiffleDepthService(), []);
    const [riffleDepthViewModel, setRiffleDepthServiceViewModel] = useState<RiffleDepthViewModel | null>(null);
    const [riffleDepthServiceDoesNotExist, setRiffleDepthServiceDoesNotExist] = useState<boolean>(false);

    //get riffle depth data
    useEffect(() => {
        if (!(props.typeIIEcoIndicator && props.typeIIEcoIndicator.ecoNeed.internalName === "passage")) {
            setRiffleDepthServiceDoesNotExist(false);
            return;
        }

        setRiffleDepthServiceViewModel(null);

        (async () => {
            try {
                let data = await riffleDepthService.getRiffleDepthData(props.site.id,
                    props.typeIIEcoIndicator!.species.id,
                    props.typeIIEcoIndicator!.lifestage.id);
                setRiffleDepthServiceViewModel(data);
                setRiffleDepthServiceDoesNotExist(false);
            }
            catch (response: any) {
                if (response.response.status === 404) {
                    setRiffleDepthServiceDoesNotExist(true);
                }
            }
        })()
    }, [props.typeIIEcoIndicator, riffleDepthService, props.site]);

    return (
        <div className="sectionB">
            <div className="flow-criteria">
                <div className="section-header">Flow Criteria for Focal Flow Components</div>
                <div className="flow-criteria-wrapper">
                    <div className="flow-criteria-image">
                        <img src={diagram} alt="flow diagram" />
                    </div>
                    <div className="flow-criteria-text">
                        <p>Cupcake ipsum dolor sit amet tootsie roll. Sugar plum tootsie roll halvah lemon drops I love powder jujubes I love cotton candy. Bonbon caramels cotton candy pie lemon drops I love dragée icing I love. Pudding tiramisu gingerbread I love biscuit. Dragée biscuit tootsie roll bonbon chocolate cake I love sugar plum sweet caramels jelly.</p>
                        <p>Cupcake ipsum dolor sit amet tootsie roll. Sugar plum tootsie roll halvah lemon drops I love powder jujubes I love cotton candy. Bonbon caramels cotton candy pie lemon drops I love dragée icing I love. Pudding tiramisu gingerbread I love biscuit. Dragée biscuit tootsie roll bonbon chocolate cake I love sugar plum sweet caramels jelly.</p>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="select-eco-wrapper">
                <div className="select-eco">
                    <div className="section-header">Verify Ecological Flow Criteria</div>
                    {lookups &&
                        <>
                            <DropdownComponentWithFunction
                                options={[...lookups.typeIIEcoIndicators.map(x => ({ key: x.id, text: `${x.species.displayName} ${x.lifestage.displayName} ${x.ecoNeed.displayName}`, value: x.id }) as IDropdownOption)]}
                                placeholder="Select Eco Indicator"
                                selected={props.selectedEcoIndicatorId ?? undefined}
                                setSelected={props.setSelectedEcoIndicatorId} />
                        </>
                    }
                </div>
            </div>
            <div className="section-b-chart-wrapper" ref={divRef}>
                <div className="chart-wrapper">
                    <div className="chart-header">
                        Riffle Depth
                    </div>
                    <div className="chart">
                        {(props.typeIIEcoIndicator && props.typeIIEcoIndicator.ecoNeed.internalName === "passage") && riffleDepthViewModel &&
                            <RiffleDepthCurve
                                riffleDepthViewModel={riffleDepthViewModel}
                                site={props.site}
                                typeIIEcoIndicator={props.typeIIEcoIndicator!}
                                width={chartWidth}
                                height={500} />
                        }
                        {!((props.typeIIEcoIndicator && props.typeIIEcoIndicator.ecoNeed.internalName === "passage") && riffleDepthViewModel) && !riffleDepthServiceDoesNotExist &&
                            <div className="missing-chart-text-wrapper">
                                <div className="missing-chart-text">Please select a Passage Type II Eco Indicator above to show this graph.</div>
                                <div className="missing-chart-text">*** Note that not every combo of eco indicator has data!</div>
                            </div>
                        }
                        {riffleDepthServiceDoesNotExist &&
                            <div className="missing-chart-text-wrapper">
                                <div className="missing-chart-text">A Riffle Depth Curve does not exist for this combination</div>
                            </div>
                        }
                    </div>
                </div>
                <div className="chart-wrapper">
                    <div className="chart-header">
                        Flow-Riffle Passage Curve
                    </div>
                    <div className="chart">
                        {(props.typeIIEcoIndicator && props.typeIIEcoIndicator.ecoNeed.internalName === "passage") && riffleDepthViewModel &&
                            <RifflePassageCurve
                                riffleDepthViewModel={riffleDepthViewModel}
                                site={props.site}
                                typeIIEcoIndicator={props.typeIIEcoIndicator!}
                                width={chartWidth}
                                height={500} />
                        }
                        {!((props.typeIIEcoIndicator && props.typeIIEcoIndicator.ecoNeed.internalName === "passage") && riffleDepthViewModel) && !riffleDepthServiceDoesNotExist &&
                            <div className="missing-chart-text-wrapper">
                                <div className="missing-chart-text">Please select a Passage Type II Eco Indicator above to show this graph.</div>
                                <div className="missing-chart-text">*** Note that not every combo of eco indicator has data!</div>
                            </div>
                        }
                        {riffleDepthServiceDoesNotExist &&
                            <div className="missing-chart-text-wrapper">
                                <div className="missing-chart-text">A Riffle Passage Curve does not exist for this combination</div>
                            </div>
                        }
                    </div>
                </div>
                <div className="chart-wrapper">
                    <div className="chart-header">
                        Habitat Suitability Curves
                    </div>
                    <div className="chart">
                        {habitatSuitabilityViewModel && props.typeIIEcoIndicator && props.typeIIEcoIndicator.ecoNeed.internalName === "habitatArea" &&
                            <HabitatSuitabilityCurve
                                habitatSuitabilityViewModel={habitatSuitabilityViewModel}
                                species={props.typeIIEcoIndicator.species}
                                lifestage={props.typeIIEcoIndicator.lifestage}
                                width={chartWidth}
                                height={500}
                            />
                        }
                        {!(habitatSuitabilityViewModel && props.typeIIEcoIndicator && props.typeIIEcoIndicator.ecoNeed.internalName === "habitatArea") && !habitatSuitabilityDoesNotExist &&
                            <div className="missing-chart-text-wrapper">
                                <div className="missing-chart-text">Please select a Habitat Type II Eco Indicator above to show this graph.</div>
                                <div className="missing-chart-text">*** Note that not every combo of species and lifestage has data!</div>
                            </div>
                        }
                        {habitatSuitabilityDoesNotExist &&
                            <div className="missing-chart-text-wrapper">
                                <div className="missing-chart-text">A Habitat Suitability Curve does not exist for this combination</div>
                            </div>
                        }

                    </div>
                </div>
                <div className="chart-wrapper">
                    <div className="chart-header">
                        Flow-Habitat Area Curve
                    </div>
                    <div className="chart">
                        {flowHabitatAreaViewModel && props.typeIIEcoIndicator && props.typeIIEcoIndicator.ecoNeed.internalName === "habitatArea" &&
                            <FlowHabitatAreaCurve
                                flowHabitatAreaViewModel={flowHabitatAreaViewModel}
                                site={props.site}
                                species={props.typeIIEcoIndicator.species}
                                lifestage={props.typeIIEcoIndicator.lifestage}
                                width={chartWidth}
                                height={500}
                            />
                        }
                        {!(flowHabitatAreaViewModel && props.typeIIEcoIndicator && props.typeIIEcoIndicator.ecoNeed.internalName === "habitatArea") && !flowHabitatAreaDoesNotExist &&
                            <div className="missing-chart-text-wrapper">
                                <div className="missing-chart-text">Please select a Habitat Type II Eco Indicator above to show this graph.</div>
                                <div className="missing-chart-text">*** Note that not every combo of species and lifestage has data!</div>
                            </div>
                        }
                        {flowHabitatAreaDoesNotExist &&
                            <div className="missing-chart-text-wrapper">
                                <div className="missing-chart-text">A Flow-Habitat Area Curve does not exist for this combination</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};