import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";
import { Site } from "../models/lookups";

export interface FunctionalFlowViewModel {
    functionalFlow: FunctionalFlow,
    functionalFlowYears: FunctionalFlowYear[]
}

export interface FunctionalFlowMetricViewModel {
    functionalFlow: FunctionalFlow,
    flowMetricId: string,
    functionalFlowMetricYears: FunctionalFlowMetric[]
}

export interface FunctionalFlowMetric {
    diversionRate: number,
    functionalFlowYears: FunctionalFlowYear[]
}

export interface FunctionalFlow {
    id: number,
    siteId: number,
    fileName: string,
    active: boolean,

    site: Site
}

export interface FunctionalFlowYear {
    year: number,
    
    fallPulseMagnitude: number | null,
    fallPulseTiming: number | null,
    fallPulseDuration: number | null,

    wetSeasonLowBaseflow: number | null,
    wetSeasonMedianBaseflow: number | null,
    wetSeasonTiming: number | null,
    wetSeasonDuration: number | null,

    floodMagnitude2Year: number | null,
    floodMagnitude5Year: number | null,
    floodMagnitude10Year: number | null,

    floodDuration2Year: number | null,
    floodDuration5Year: number | null,
    floodDuration10Year: number | null,

    floodFrequency2Year: number | null,
    floodFrequency5Year: number | null,
    floodFrequency10Year: number | null,

    springRecessionMagnitude: number | null,
    springTiming: number | null,
    springDuration: number | null,
    springRateOfChange: number | null,

    drySeasonMedianBaseflow: number | null,
    drySeasonHighBaseflow: number | null,
    drySeasonTiming: number | null,
    drySeasonDuration: number | null
}

export class FunctionalFlowService {
//    private authToken: string;

    // constructor(authToken: string) {
    //     this.authToken = authToken;
    // }

    async getFunctionalFlowData(functionalFlowId: number): Promise<FunctionalFlowViewModel> {
        let config: AxiosRequestConfig = {
            headers: {
                //"Authorization": "Bearer " + this.authToken
            },
            params: {"functionalFlowId": functionalFlowId}
        };
        const result = await axios.get<FunctionalFlowViewModel>(Config.apiEndpoint + "/FunctionalFlow/FunctionalFlowData", config);
        return result.data;
    }

    async getFunctionalFlowDataBySiteId(siteId: number): Promise<FunctionalFlowViewModel> {
        let config: AxiosRequestConfig = {
            headers: {
                //"Authorization": "Bearer " + this.authToken
            }, 
            params: {"siteId": siteId}
        };
        const result = await axios.get<FunctionalFlowViewModel>(Config.apiEndpoint + "/FunctionalFlow/FunctionalFlowDataBySiteId", config);
        return result.data;
    }

    async getFunctionalFlowMetricDataBySiteId(siteId: number, flowMetricId: number): Promise<FunctionalFlowMetricViewModel> {
        let config: AxiosRequestConfig = {
            headers: {
                //"Authorization": "Bearer " + this.authToken
            }, 
            params: {
                "siteId": siteId, 
                "flowMetricId": flowMetricId
            }
        };
        const result = await axios.get<FunctionalFlowMetricViewModel>(Config.apiEndpoint + "/FunctionalFlow/FunctionalFlowMetricDataBySiteId", config);
        return result.data;
    }

}
