import { MainWrapper } from "./components/mainWrapper/MainWrapper";
import logo from "./images/small_device.png"
import "./App.scss";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { createContext, useEffect, useMemo, useState } from "react";
import { Lookups } from "./models/lookups";
import { LookupsService } from "./services/LookupsService";
import { VersionNumber } from "./components/versionNumber/VersionNumber";
import Config from "./config/Config";

export const LookupsContext = createContext<[Lookups | null]>([null]);

const App = () => {
    const [lookups, setLookups] = useState<Lookups | null>(null);
    const lookupsService = useMemo(() => new LookupsService(/*auth.user?.access_token ?? ""*/), [/*auth*/]);
    const auth = useAuth();

    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect();
        }
    }, [auth]);

    useEffect(() => {
        if (!auth.isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            const lookups = await lookupsService.getLookups();
            setLookups(lookups);
        }

        fetchData().catch(console.error);
    }, [auth, lookupsService]);

    return (
        <>
            <div className="App">
                <LookupsContext.Provider value={[lookups]}>
                    <MainWrapper />
                </LookupsContext.Provider>
                <VersionNumber version={Config.version} environment={Config.environment} />

            </div>
            <div className="hide">
                <img src={logo} alt="logo" />
                PLEASE VISIT THIS SITE FROM A LARGER DEVICE
            </div>
        </>
    );
}

export default App;
