import { Config } from 'plotly.js';
import Plot from 'react-plotly.js';
import { Lifestage, Species } from '../../models/lookups';
import { HabitatSuitabilityViewModel } from '../../services/HabitatSuitabilityService';
import { IPlotLayoutProps } from './helpers/PlotLayoutProps';

export interface IHabitatSuitabilityCurveProps extends IPlotLayoutProps {
    habitatSuitabilityViewModel: HabitatSuitabilityViewModel,
    species: Species,
    lifestage: Lifestage
}

export const HabitatSuitabilityCurve = (props: IHabitatSuitabilityCurveProps) => {

    const config: Partial<Config> = {
        toImageButtonOptions: {
            format: 'png',
            filename: `Habitat Suitability - ${props.species.displayName} - ${props.lifestage.displayName}`,
            scale: 3
        },
        displaylogo: false,
    }

    return (
        <>
            {props &&
                <Plot
                    data={[{
                        //Depth
                        x: props.habitatSuitabilityViewModel.habitatSuitabilityData.filter(x => x.suitabilityParameterId === 1).map((x) => x.value),
                        y: props.habitatSuitabilityViewModel.habitatSuitabilityData.filter(x => x.suitabilityParameterId === 1).map((x) => x.suitability),
                        name: 'Depth',
                        marker: {
                            color: "black",
                            size: 0
                        },
                        line: {
                            width: 2,
                            dash: 'dash'
                        },
                        showlegend: true,
                    },
                    {
                        //Velocity
                        x: props.habitatSuitabilityViewModel.habitatSuitabilityData.filter(x => x.suitabilityParameterId === 2).map((x) => x.value),
                        y: props.habitatSuitabilityViewModel.habitatSuitabilityData.filter(x => x.suitabilityParameterId === 2).map((x) => x.suitability),
                        name: 'Velocity',
                        marker: {
                            color: "grey",
                            size: 0,
                        },
                        line: {
                            width: 2,
                            dash: 'solid'
                        },
                        showlegend: true
                    }]}
                    layout={{
                        width: props.width,
                        height: props.height,
                        title: `<b>${props.species.displayName} ${props.lifestage.displayName} Suitability</b>`,
                        font: {
                            family: 'Arial,Helvetica',
                            size: 12,
                            color: '#656565',
                        },
                        xaxis: {
                            title: `<b>Depth (ft), Velocity (fps)</b>`,
                            showline: true,
                            rangemode: 'nonnegative',
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true
                        },
                        yaxis: {
                            title: `<b>Habitat Suitability</b>`,
                            showline: true,
                            rangemode: 'nonnegative',
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true,
                        },
                        plot_bgcolor: 'rgba(0,0,0,0)', // transparent
                        paper_bgcolor: 'rgba(0,0,0,0)', // transparent
                        hovermode: 'x unified',
                        legend: {
                            font: {
                                family: 'sans-serif',
                                size: 12,
                                color: '#000'
                            },
                            traceorder: 'reversed',
                        },
                    }}
                    config={config}
                />
            }
        </>
    );
};