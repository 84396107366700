import { Config } from 'plotly.js';
import { useMemo } from 'react';
import Plot from 'react-plotly.js';
import { DiversionYear } from '../../models/DiversionYear';
import { FlowMetric, Site } from '../../models/lookups';
import { IPlotLayoutProps } from './helpers/PlotLayoutProps';
import { make_scatter_data } from './helpers/ScatterQuantiles';

export interface IAnnualDeltaMetricsScatterPlotProps extends IPlotLayoutProps {
    site: Site,
    metric: FlowMetric,
    diversionYears: DiversionYear[],
    yAxisTitle: string,
}

export const DiversionYearScatterPlot = (props: IAnnualDeltaMetricsScatterPlotProps) => {
    const years = useMemo(() => props.diversionYears.map(y => y.year), [props.diversionYears]);
    const divRates = useMemo(() => props.diversionYears
        .map(y => y.values.map(d => d.diversionRate))
        .flat()
        .filter((v, i, s) => s.indexOf(v) === i)
        .map(dr => parseFloat(dr)), [props.diversionYears]);
    const data = useMemo(() => {
        var data = props.diversionYears.map(y => y.values.map(d => d.value));
        var transposed = data.map((_, colIndex) => data.map(row => row[colIndex]));
        return transposed;
    }, [props.diversionYears]);

    const config: Partial<Config> = {
        toImageButtonOptions: {
            format: 'png',
            filename: `Diversion Year - ${props.site.siteName} - ${props.metric.displayName}`,
            scale: 3
        },
        displaylogo: false,
    }

    return (
        <>
            {props && divRates.length > 0 &&
                <Plot
                    data={make_scatter_data(divRates, years, data)}
                    layout={{
                        width: props.width,
                        height: props.height,
                        font: {
                            family: 'Arial,Helvetica',
                            size: 12,
                            color: 'black',
                        },
                        xaxis: {
                            title: `<b>Diversion Rate (%)</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            ticks: 'inside',
                            mirror: true,
                        },
                        yaxis: {
                            title: `<b>props.yAxisTitle</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true,
                            hoverformat: '.3f',
                        },
                        plot_bgcolor: 'white',
                        paper_bgcolor: 'white',
                        hovermode: 'closest',
                        showlegend: false,
                    }}
                    config={config}
                />
            }
        </>
    );
};
