import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";

export interface FlowHabitatAreaViewModel {
    flowHabitatAreaData: FlowHabitatAreaPair[],
}

export interface FlowHabitatAreaPair {
    flowCfs: number,
    habitatAreaSqFt: number
}

export class FlowHabitatAreaService {
//    private authToken: string;

    // constructor(authToken: string) {
    //     this.authToken = authToken;
    // }

    async getFlowHabitatAreaData(siteId: number, speciesId: number, lifestageId: number): Promise<FlowHabitatAreaViewModel> {
        let config: AxiosRequestConfig = {
            headers: {
                //"Authorization": "Bearer " + this.authToken
            },
            params: {"siteId": siteId, "speciesId": speciesId, "lifestageId": lifestageId}
        };
        const result = await axios.get<FlowHabitatAreaViewModel>(Config.apiEndpoint + "/FlowHabitatArea", config);
        return result.data;
    }

}
