import { Config } from 'plotly.js';
import { useCallback, useContext, useMemo } from 'react';
import Plot from 'react-plotly.js';
import { LookupsContext } from '../../App';
import { FlowMetric, Site } from '../../models/lookups';
import { WaterYear } from "../../services/FlowMatrixService";
import { FunctionalFlowViewModel, FunctionalFlowYear } from '../../services/FunctionalFlowService';
import { IPlotLayoutProps } from './helpers/PlotLayoutProps';

export interface IWaterYearBoxPlotProps extends IPlotLayoutProps {
    site?: Site,
    metric: FlowMetric,
    waterYears: Array<WaterYear>,
    functionalFlowViewModel: FunctionalFlowViewModel,
}

export const WaterYearBoxPlot = (props: IWaterYearBoxPlotProps) => {

    const config: Partial<Config> = {
        toImageButtonOptions: {
            format: 'png',
            filename: `Water Year Box Plot - ${props.site?.siteName} - ${props.metric.displayName}`,
            scale: 3
        },
        displaylogo: false,
        edits: { legendText: false }
    }

    const [lookups] = useContext(LookupsContext);

    const allData = useMemo(() => {
        return props.functionalFlowViewModel?.functionalFlowYears.map((year: FunctionalFlowYear) => year[props.metric.internalPropertyName.charAt(0).toLowerCase() + props.metric.internalPropertyName.slice(1) as keyof (FunctionalFlowYear)])
    }, [props]);

    const filterFunctionData = useCallback((waterYearTypeId: number) => {
        return props.functionalFlowViewModel.functionalFlowYears
            .filter((year: FunctionalFlowYear) => props.waterYears
                .filter((wy: WaterYear) => wy.waterYearTypeId === waterYearTypeId)
                .map((x) => x.year)
                .includes(year.year))
            .map((year: FunctionalFlowYear) => year[props.metric.internalPropertyName.charAt(0).toLowerCase() + props.metric.internalPropertyName.slice(1) as keyof (FunctionalFlowYear)])
    }, [props.functionalFlowViewModel, props.waterYears, props.metric])

    const filterFunctionYears = useCallback((waterYearTypeId: number) => {
        return props.functionalFlowViewModel.functionalFlowYears
            .filter((year: FunctionalFlowYear) => props.waterYears
                .filter((wy: WaterYear) => wy.waterYearTypeId === waterYearTypeId)
                .map((x) => x.year)
                .includes(year.year)).map(x => x.year.toString())
    }, [props.functionalFlowViewModel, props.waterYears])


    const moderateData = useMemo(() => {
        if (!(lookups)) { return }
        return filterFunctionData(lookups.waterYearTypes.find((x) => x.internalName === "moderate")!.id)
    }, [filterFunctionData, lookups]);

    const wetData = useMemo(() => {
        if (!(lookups)) { return }
        return filterFunctionData(lookups.waterYearTypes.find((x) => x.internalName === "wet")!.id)
    }, [filterFunctionData, lookups]);

    const dryData = useMemo(() => {
        if (!(lookups)) { return }
        return filterFunctionData(lookups.waterYearTypes.find((x) => x.internalName === "dry")!.id)
    }, [filterFunctionData, lookups]);


    return (
        <>

            {lookups && props.functionalFlowViewModel && props.waterYears &&
                <Plot
                    data={[
                        {
                            "type": "box",
                            "name": "All",
                            "text": props.waterYears.map(x => x.year.toString()), // tooltip
                            "y": allData,
                            "marker": {
                                "color": "black",
                                "size": 7
                            },
                            boxpoints: 'all',
                        },
                        {
                            "type": "box",
                            "name": "Wet",
                            "text": filterFunctionYears(lookups.waterYearTypes.find((x) => x.internalName === "wet")!.id), // tooltip
                            "y": wetData,
                            "marker": {
                                "color": "darkslateblue",
                                "size": 7
                            },
                            boxpoints: 'all',
                        },
                        {
                            "type": "box",
                            "name": "Moderate",
                            "text": filterFunctionYears(lookups.waterYearTypes.find((x) => x.internalName === "moderate")!.id), // tooltip
                            "y": moderateData,
                            "marker": {
                                "color": "dimgray",
                                "size": 7
                            },
                            boxpoints: 'all',
                        },
                        {
                            "type": "box",
                            "name": "Dry",
                            "text": filterFunctionYears(lookups.waterYearTypes.find((x) => x.internalName === "dry")!.id), // tooltip
                            "y": dryData,
                            "marker": {
                                "color": "firebrick",
                                "size": 7
                            },
                            boxpoints: 'all',
                        }
                    ]}
                    layout={{
                        width: props.width,
                        height: props.height,
                        font: {
                            family: 'Arial,Helvetica',
                            size: 12,
                            color: 'black',
                        },
                        xaxis: {
                            title: "Water Year Type",
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            ticks: 'inside',
                            mirror: true,
                            tickvals: [0, 1, 2, 3],
                            ticktext: ['All', 'Wet', 'Moderate', 'Dry'],
                        },
                        yaxis: {
                            title: `${props.metric.displayName} ${props.metric.unit}`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true,
                            hoverformat: '.2f',
                        },
                        plot_bgcolor: 'white',
                        paper_bgcolor: 'transparent', // don't change this
                        hovermode: 'x',
                        showlegend: false,
                    }}
                    config={config}
                />
            }
        </>
    );
};

