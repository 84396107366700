import { Config } from 'plotly.js';
import Plot from 'react-plotly.js';
import { Lifestage, Site, Species } from '../../models/lookups';
import { FlowHabitatAreaViewModel } from '../../services/FlowHabitatAreaService';
import { IPlotLayoutProps } from './helpers/PlotLayoutProps';

export interface IFlowHabitatAreaCurveProps extends IPlotLayoutProps {
    flowHabitatAreaViewModel: FlowHabitatAreaViewModel,
    site: Site,
    species: Species,
    lifestage: Lifestage
}

export const FlowHabitatAreaCurve = (props: IFlowHabitatAreaCurveProps) => {

    const config: Partial<Config> = {
        toImageButtonOptions: {
            format: 'png',
            filename: `Flow Habitat Area - ${props.site.siteName} - ${props.species.displayName} - ${props.lifestage.displayName}`,
            scale: 3
        },
        displaylogo: false,
    }

    return (
        <>
            {props &&
                <Plot
                    data={[{
                        x: props.flowHabitatAreaViewModel.flowHabitatAreaData.map((x) => x.flowCfs),
                        y: props.flowHabitatAreaViewModel.flowHabitatAreaData.map((x) => x.habitatAreaSqFt),
                        marker: {
                            color: "black",
                            size: 0
                        },
                        line: {
                            width: 2,
                        },
                        showlegend: false,
                    }]}
                    layout={{
                        width: props.width,
                        height: props.height,
                        title: `<b>${props.site.siteName}: ${props.species.displayName} ${props.lifestage.displayName} Habitat Area</b>`,
                        font: {
                            family: 'Arial,Helvetica',
                            size: 12,
                            color: '#656565',
                        },
                        xaxis: {
                            title: `<b>Flowrate (cfs)</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true
                        },
                        yaxis: {
                            title: `<b>Habitat Area (ft<sup>2</sup>)</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true,
                        },
                        plot_bgcolor: 'rgba(0,0,0,0)', // transparent
                        paper_bgcolor: 'rgba(0,0,0,0)', // transparent
                        hovermode: 'x unified',
                        legend: {
                            font: {
                                family: 'sans-serif',
                                size: 12,
                                color: '#000'
                            },
                            traceorder: 'reversed',
                        },
                    }}
                    config={config}
                />
            }
        </>
    );
};