import { Config } from 'plotly.js';
import { useCallback, useContext, useMemo } from 'react';
import Plot from 'react-plotly.js';
import { LookupsContext } from '../../App';
import { FlowMetric, Site } from '../../models/lookups';
import { WaterYear } from "../../services/FlowMatrixService";
import { FunctionalFlowViewModel, FunctionalFlowYear } from '../../services/FunctionalFlowService';
import { IPlotLayoutProps } from './helpers/PlotLayoutProps';

export interface IWaterYearLineGraphProps extends IPlotLayoutProps {
    site?: Site,
    metric: FlowMetric,
    waterYears: Array<WaterYear>,
    functionalFlowViewModel: FunctionalFlowViewModel,
}

export const WaterYearLineGraph = (props: IWaterYearLineGraphProps) => {

    const config: Partial<Config> = {
        toImageButtonOptions: {
            format: 'png',
            filename: `Water Year Line Graph - ${props.site?.siteName} - ${props.metric.displayName}`,
            scale: 3
        },
        displaylogo: false,
        edits: { legendText: false }
    }

    const [lookups] = useContext(LookupsContext);

    const filterFunction = useCallback((waterYearTypeId: number) => {
        return props.functionalFlowViewModel.functionalFlowYears
            .filter((year: FunctionalFlowYear) => props.waterYears
                .filter((wy: WaterYear) => wy.waterYearTypeId === waterYearTypeId)
                .map((x) => x.year)
                .includes(year.year))
            .map((year: FunctionalFlowYear) => year[props.metric.internalPropertyName.charAt(0).toLowerCase() + props.metric.internalPropertyName.slice(1) as keyof (FunctionalFlowYear)])
    }, [props.functionalFlowViewModel, props.waterYears, props.metric])

    const allData = useMemo(() => {
        return props.functionalFlowViewModel?.functionalFlowYears.sort((a, b) => a.year - b.year).map((year: FunctionalFlowYear) => year[props.metric.internalPropertyName.charAt(0).toLowerCase() + props.metric.internalPropertyName.slice(1) as keyof (FunctionalFlowYear)])
    }, [props]);

    const moderateData = useMemo(() => {
        if (!(lookups)) { return }
        return filterFunction(lookups.waterYearTypes.find((x) => x.internalName === "moderate")!.id)
    }, [filterFunction, lookups]);

    const wetData = useMemo(() => {
        if (!(lookups)) { return }
        return filterFunction(lookups.waterYearTypes.find((x) => x.internalName === "wet")!.id)
    }, [filterFunction, lookups]);

    const dryData = useMemo(() => {
        if (!(lookups)) { return }
        return filterFunction(lookups.waterYearTypes.find((x) => x.internalName === "dry")!.id)
    }, [filterFunction, lookups]);


    return (
        <>
            {lookups && props.functionalFlowViewModel && props.waterYears && allData &&
                <Plot
                    data={[{
                        x: props.waterYears.map((x) => x.year),
                        y: allData,
                        name: "All",
                        marker: {
                            color: "black",
                            size: 0
                        },
                        line: {
                            width: 2,
                        },
                        showlegend: false,
                    },
                    {
                        x: props.waterYears.filter((wy: WaterYear) => wy.waterYearTypeId === lookups.waterYearTypes.find((x) => x.internalName === "moderate")!.id).map((x) => x.year),
                        y: moderateData,
                        name: "Moderate",
                        marker: {
                            color: "dimgray",
                            size: 12,
                            symbol: "circle",
                        },
                        line: {
                            width: 0,
                        },
                        showlegend: true,
                        hovertemplate: '<b>%{x}</b> (Moderate)<br>%{y:.2f} cfs<extra></extra>',
                    },
                    {
                        x: props.waterYears.filter((wy: WaterYear) => wy.waterYearTypeId === lookups.waterYearTypes.find((x) => x.internalName === "dry")!.id).map((x) => x.year),
                        y: dryData,
                        name: "Dry",
                        marker: {
                            color: "firebrick",
                            size: 12,
                            symbol: "square",
                        },
                        line: {
                            width: 0,
                        },
                        showlegend: true,
                        hovertemplate: `<b>%{x}</b> (Dry)<br>%{y:.2f} cfs<extra></extra>`,
                    },
                    {
                        x: props.waterYears.filter((wy: WaterYear) => wy.waterYearTypeId === lookups.waterYearTypes.find((x) => x.internalName === "wet")!.id).map((x) => x.year),
                        y: wetData,
                        name: "Wet",
                        marker: {
                            color: "darkslateblue",
                            size: 12,
                            symbol: "diamond",
                        },
                        line: {
                            width: 0,
                        },
                        showlegend: true,
                        hovertemplate: '<b>%{x}</b> (Wet)<br>%{y:.2f} cfs<extra></extra>',
                    }]}
                    layout={{
                        width: props.width,
                        height: props.height,
                        font: {
                            family: 'Arial,Helvetica',
                            size: 12,
                            color: 'black',
                        },
                        xaxis: {
                            title: 'Water Year',
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            ticks: 'inside',
                            mirror: true,
                        },
                        yaxis: {
                            title: `${props.metric.displayName} (${props.metric.unit})`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true,
                            hoverformat: '.2f',
                        },
                        plot_bgcolor: 'white',
                        paper_bgcolor: 'transparent', // don't change this
                        hovermode: 'closest',
                        showlegend: true,
                    }}
                    config={config}
                />
            }
        </>
    );
};

