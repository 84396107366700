import { Config, Data } from 'plotly.js';
import { useMemo } from 'react';
import Plot from 'react-plotly.js';
import { Site, TypeIIEcoIndicator } from '../../models/lookups';
import { RiffleDepthViewModel } from '../../services/RiffleDepthService';
import { IPlotLayoutProps } from './helpers/PlotLayoutProps';

export interface IRiffleDepthCurveProps extends IPlotLayoutProps {
    riffleDepthViewModel: RiffleDepthViewModel,
    typeIIEcoIndicator: TypeIIEcoIndicator,
    site: Site
}

export const RiffleDepthCurve = (props: IRiffleDepthCurveProps) => {

    const config: Partial<Config> = {
        toImageButtonOptions: {
            format: 'png',
            filename: `Riffle Depth - ${props.site.siteName} - ${props.typeIIEcoIndicator.species.displayName} - ${props.typeIIEcoIndicator.lifestage.displayName}`,
            scale: 3
        },
        displaylogo: false,
    }

    const depthCurves: Data[] = useMemo(() => {

        const blues = [ // blues colorscale
            'rgb(8, 48, 107)', 'rgb(8, 64, 130)', 'rgb(8, 79, 153)',
            'rgb(18, 94, 166)', 'rgb(30, 109, 178)', 'rgb(45, 125, 187)',
            'rgb(60, 140, 195)', 'rgb(78, 154, 203)', 'rgb(97, 167, 210)',
            'rgb(119, 181, 217)', 'rgb(143, 194, 222)', 'rgb(165, 205, 227)',
            'rgb(184, 213, 234)'
        ];
        
        let data: Array<Data> = new Array<Data>();
        for (let i = 0; i < props.riffleDepthViewModel.riffleDepthValuesData.length; i++) {
            data = data.concat([{
                mode: 'lines',
                name: `Q = ${props.riffleDepthViewModel.riffleDepthValuesData[i].columnName} cfs`,
                x: props.riffleDepthViewModel.distances,
                y: props.riffleDepthViewModel.riffleDepthValuesData[i].depthValues,
                line: {
                    color: blues[i],                    
                },
                hovertemplate: '%{y:.1f} cfs',
            }]);
        };
        return data
    }, [props.riffleDepthViewModel]);

    const thresholdMarkers: Data[] = useMemo(() => {
        const markers = ['circle','square','diamond','cross','x'];

        let data: Array<Data> = new Array<Data>();
        for (let i = 0; i < props.riffleDepthViewModel.thresholdDistanceAndFlow.length; i++) {
            data = data.concat([{
                x: [props.riffleDepthViewModel.thresholdDistanceAndFlow[i].distance],
                y: [props.typeIIEcoIndicator.passageDepthFt],
                type: 'scatter',
                mode: 'markers',
                name: `Qpass = ${props.riffleDepthViewModel.thresholdDistanceAndFlow[i].flowCfs.toFixed(2)} cfs`,
                marker: {
                    color: 'black',
                    size: 16,
                    symbol: markers[i],
                }
            }]);
        };
        return data
    }, [props.riffleDepthViewModel, props.typeIIEcoIndicator]);


    return (
        <>
            {props &&
                <Plot
                    data={[
                        ...depthCurves,
                        ...thresholdMarkers,
                        {
                            //Depth
                            x: props.riffleDepthViewModel.distances,
                            y: new Array(props.riffleDepthViewModel.distances.length).fill(props.typeIIEcoIndicator.passageDepthFt),
                            name: 'Threshold',
                            marker: {
                                color: "red",
                                size: 2
                            },
                            line: {
                                width: 2,
                                dash: 'dash'
                            },
                            showlegend: true,
                        }, 
                        
                    ]}
                    layout={{
                        width: props.width,
                        height: props.height,
                        title: `<b>${props.typeIIEcoIndicator.species.displayName} ${props.typeIIEcoIndicator.lifestage.displayName} Riffle Depth</b>`,
                        font: {
                            family: 'Arial,Helvetica',
                            size: 12,
                            color: 'black',
                        },
                        xaxis: {
                            title: `<b>Distance (ft)</b>`,
                            showline: true,
                            rangemode: 'nonnegative',
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true
                        },
                        yaxis: {
                            title: `<b>Thalweg Depth (ft)</b>`,
                            showline: true,
                            rangemode: 'nonnegative',
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true,
                        },
                        plot_bgcolor: 'rgba(0,0,0,0)', // transparent
                        paper_bgcolor: 'rgba(0,0,0,0)', // transparent
                        hovermode: 'x',
                        legend: {
                            font: {
                                family: 'sans-serif',
                                size: 12,
                                color: '#000'
                            },
                            traceorder: 'reversed',
                        },
                    }}
                    config={config}
                />
            }
        </>
    );
};