import { Button } from "semantic-ui-react";
import "./ButtonGroupComponent.scss";

export interface IButtonGroupComponentProps {
    labels: string[] | React.ReactNode[];
    activeItem: string;
    setActiveItem: Function;
}

export const ButtonGroupComponent = (props: IButtonGroupComponentProps) => {

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        props?.setActiveItem(e.currentTarget.id)
    }
    return (
        <div className="button-group-component">
            <Button.Group>
                {props.labels.map((label, index) => {
                    return (
                        <Button
                            key={index}
                            id={label}
                            className={props?.activeItem === label ? "active" : ""}
                            onClick={handleClick}
                        >
                            {label}
                        </Button>
                    )
                })}
            </Button.Group>
        </div>
    );
};