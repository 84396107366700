import { Config } from 'plotly.js';
import { useContext, useMemo } from 'react';
import Plot from 'react-plotly.js';
import { LookupsContext } from '../../App';
import { FlowMetric, Site } from '../../models/lookups';
import { FunctionalFlowMetricViewModel, FunctionalFlowYear } from '../../services/FunctionalFlowService';
import { IPlotLayoutProps } from './helpers/PlotLayoutProps';
import { make_scatter_data } from './helpers/ScatterQuantiles';

export interface IAnnualFunctionalFlowMetricScatterPlotProps extends IPlotLayoutProps {
    site: Site,
    metric: FlowMetric,
    functionalFlowMetricViewModel: FunctionalFlowMetricViewModel,
    divRates: number[],
}

export const AnnualFunctionalFlowMetricScatterPlot = (props: IAnnualFunctionalFlowMetricScatterPlotProps) => {

    const config: Partial<Config> = {
        toImageButtonOptions: {
            format: 'png',
            filename: `Annual Functional Flow Scatter Plot - ${props.site.siteName} - ${props.metric.displayName}`,
            scale: 3
        },
        displaylogo: false,
    }

    const [lookups] = useContext(LookupsContext);

    const years: number[] = useMemo(() => {
        return props.functionalFlowMetricViewModel.functionalFlowMetricYears.flatMap(y => y.functionalFlowYears.map((x) => x.year)).filter((v, i, a) => a.indexOf(v) === i);
    }, [props.functionalFlowMetricViewModel]);

    const data: number[][] = useMemo(() => {
        return props.functionalFlowMetricViewModel.functionalFlowMetricYears
            .map(rate => rate.functionalFlowYears
                .map(x => x[props.metric.internalPropertyName.charAt(0).toLowerCase() + props.metric.internalPropertyName.slice(1) as keyof (FunctionalFlowYear)]) as number[]);
    }, [props.functionalFlowMetricViewModel, props.metric]);

    return (
        <>
            {lookups && props.functionalFlowMetricViewModel && props.metric &&
                <Plot
                    data={make_scatter_data(props.divRates, years, data)}
                    layout={{
                        width: props.width,
                        height: props.height,
                        font: {
                            family: 'Arial,Helvetica',
                            size: 12,
                            color: 'black',
                        },
                        xaxis: {
                            title: `<b>Diversion Rate (%)</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            ticks: 'inside',
                            mirror: true,
                        },
                        yaxis: {
                            title: `<b>${props.metric.displayName} ${props.metric.unit}</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true,
                            hoverformat: '.3f',
                        },
                        plot_bgcolor: 'white',
                        paper_bgcolor: 'white',
                        hovermode: 'closest',
                        showlegend: false,
                    }}
                    config={config}
                />
            }
        </>
    );
};
