import { Outlet, Route, Routes } from "react-router-dom";
import { Navbar } from "../navbar/Navbar";
import { ResourcePage } from "../resourcePage/ResourcePage";
import { DataPage } from "../dataPage/DataPage";
import { AboutPage } from "../aboutPage/AboutPage";
import "./MainWrapper.scss";

export const MainWrapper = (props: any) => {
    return (
        <div className="main-wrapper">
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<DataPage />} />
                    <Route path="resources" element={<ResourcePage />} />
                    <Route path="about" element={<AboutPage />} />
                </Route>
            </Routes>
        </div>
    );
};

function Layout() {
    return (
        <div className="layout">
            <Navbar />
            <Outlet />
        </div>
    );
}
