import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";
import { Site } from "../models/lookups";

export interface FlowMatrixViewModel {
    flowMatrix: FlowMatrix,
    flowMatrixYears: FlowMatrixYear[]
}

export interface FlowMatrix {
    Id: number,
    siteId: number,
    fileName: string,
    active: boolean,

    site: Site
}

export interface FlowMatrixYear {
    year: number,
    flowMatrixDays: FlowMatrixDay[]
}

export interface FlowMatrixDay {
    day: number,
    value: number
}

export interface WaterYear {
    year: number,
    waterYearTypeId: number
}

export class FlowMatrixService {
//    private authToken: string;

    // constructor(authToken: string) {
    //     this.authToken = authToken;
    // }

    async getFlowMatrixData(flowMatrixId: number): Promise<FlowMatrixViewModel> {
        let config: AxiosRequestConfig = {
            headers: {
                //"Authorization": "Bearer " + this.authToken
            },
            params: {"flowMatrixId": flowMatrixId}
        };
        const result = await axios.get<FlowMatrixViewModel>(Config.apiEndpoint + "/FlowMatrix/FlowMatrixData", config);

        return result.data;
    }

    async getFlowMatrixDataBySiteId(siteId: number): Promise<FlowMatrixViewModel> {
        let config: AxiosRequestConfig = {
            headers: {
                //"Authorization": "Bearer " + this.authToken
            },
            params: {"siteId": siteId}
        };
        const result = await axios.get<FlowMatrixViewModel>(Config.apiEndpoint + "/FlowMatrix/FlowMatrixDataBySiteId", config);
        return result.data;
    }

    async getWaterYearsBySiteId(siteId: number): Promise<WaterYear[]> {
        let config: AxiosRequestConfig = {
            headers: {
                // "Authorization": "Bearer " + this.authToken
            },
            params: {"siteId": siteId}
        };

        const result = await axios.get<WaterYear[]>(Config.apiEndpoint + "/FlowMatrix/SiteWaterYearTypes", config);
        return result.data;
    }

}
