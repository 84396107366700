
const asc = (arr: number[]) => [...arr].sort((a, b) => a - b);

const quantile = (arr: number[], q: number) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    if (sorted[base + 1] !== undefined) {
        return (sorted[base] + sorted[base + 1]) / 2;
    } else {
        return sorted[base];
    }
};

export function make_scatter_data(div_rates: number[], years: number[], data_to_plot: number[][]) {

    let d_base: any = {
        x: div_rates,
        type: "scatter",
        mode: "lines",
        marker: {
            color: "grey"
        },
        line: {
            width: 1,
        },
        hoverinfo: "skip"

    };
    let data: any[] = [
        {
            ...d_base,
            y: div_rates.map((_, i) => quantile(data_to_plot[i], .1)),
            name: "10%",
        },
        {
            ...d_base,
            y: div_rates.map((_, i) => quantile(data_to_plot[i], .9)),
            name: "90%",
            fill: "tonexty",
            fillcolor: "rgba(200,200,200,0.5)",
        },
        {
            ...d_base,
            y: div_rates.map((_, i) => quantile(data_to_plot[i], .25)),
            name: "25%",
        },
        {
            ...d_base,
            y: div_rates.map((_, i) => quantile(data_to_plot[i], .75)),
            name: '75%',
            fill: 'tonexty',
            fillcolor: 'rgba(200,200,200,0.5)',
        },
    ];

    let customdata = Array.from(
        Array(div_rates.length).keys(),
        x => new Array(years.length).fill(
            [...data]
                .sort((a, b) => parseFloat(a.name) - parseFloat(b.name))
                .reduce((s: string, d: any) => s + `${d.name}: ${d.y[x].toFixed(2)}<br />`, "")
        ))
        .flat();

    let x = Array.from(Array(div_rates.length).keys(), x => new Array(years.length).fill(div_rates[x]));

    data = data.concat([
        {
            type: "scatter",
            mode: "markers",
            x: x.flat(),
            y: data_to_plot.flat(),
            marker: {
                color: x.flat(),
                colorscale: "YlOrRd",
                reversescale: true,
                size: 8,
                line: {
                    width: 1,
                }
            },
            opacity: 0.625,
            meta: Array(div_rates.length).fill(years).flat(),
            customdata: customdata,
            hovertemplate: "<b>%{x}% Diversion Rate</b><br>" +
                "<b>%{meta}: %{y:.2f}</b><br>" +
                "%{customdata}<br>" +
                "<extra></extra>",
        },
        {
            ...d_base,
            mode: "lines+markers",
            y: div_rates.map((_, i) => quantile(data_to_plot[i], .5)),
            name: "Median",
            marker: {
                color: "rgb(60,60,60)",
                size: 8,
            },
            line: {
                color: "rgb(60,60,60)",
                width: 1
            },
        },
    ]);

    return data;
}
