import { useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import "./Navbar.scss";

export const Navbar = () => {
    const auth = useAuth();

    const handleLogout = useCallback(() => {
        auth.removeUser();
        auth.signoutRedirect();
    }, [auth]);

    const options = [
        { key: 1, text: "Home", as: Link, to: "/" },
        { key: 2, text: "Resources", as: Link, to: "/resources" },
        { key: 3, text: "About", as: Link, to: "/about" },
    ];

    return (
        <div className="navbar">
            <div className="nav-inner-container">
                <div className="div-left-container">
                    CEFF
                </div>
                <div className="div-right-container">
                    <div className="nav-hamburger-menu hamburger-logout" onClick={handleLogout}>
                        <div className="hamburger-logout-name">
                            Logout
                        </div>
                    </div>
                    <Dropdown text=" " className="nav-hamburger-menu" options={options} icon="bars" />
                    <div className="nav-large-menu">
                        <Link className="large-menu-option" to="/">
                            <div className="menu-name">Home</div>
                        </Link>
                        <Link className="large-menu-option" to="/resources">
                            <div className="menu-name">Resources</div>
                        </Link>
                        <Link className="large-menu-option" to="/about">
                            <div className="menu-name">About</div>
                        </Link>
                        <div className="large-menu-option" onClick={handleLogout}>
                            <div className="logout-name">Logout</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
