import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";
import { DiversionYear } from "../models/DiversionYear";

export class TypeIIEcoAlterationRiskService {
    private authToken: string;

    constructor(authToken: string) {
        this.authToken = authToken;
    }

    async getTypeIIEcoAlterationRisk(siteId: number, speciesId: number, lifestageId: number, ecoNeedId: number): Promise<DiversionYear[]> {
        const config: AxiosRequestConfig = {
            headers: {
                "Authorization": "Bearer " + this.authToken
            },
            params: {
                siteId,
                speciesId,
                lifestageId,
                ecoNeedId
            }
        };

        const url = `${Config.apiEndpoint}/TypeIIEcoAlterationRisk`;

        const result = await axios.get<DiversionYear[]>(url, config);

        return result.data;
    }
}
