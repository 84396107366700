import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";

export interface HabitatSuitabilityViewModel {
    habitatSuitabilityData: HabitatSuitabilityPair[],
}

export interface HabitatSuitabilityPair {
    suitabilityParameterId: number,
    value: number,
    suitability: number
}

export class HabitatSuitabilityService {
//    private authToken: string;

    // constructor(authToken: string) {
    //     this.authToken = authToken;
    // }

    async getHabitatSuitabilityData(speciesId: number, lifestageId: number): Promise<HabitatSuitabilityViewModel> {
        let config: AxiosRequestConfig = {
            headers: {
                //"Authorization": "Bearer " + this.authToken
            },
            params: {"speciesId": speciesId, "lifestageId": lifestageId}
        };
        const result = await axios.get<HabitatSuitabilityViewModel>(Config.apiEndpoint + "/HabitatSuitability", config);
        return result.data;
    }

}
