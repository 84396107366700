import { useCallback } from "react";
import { Table } from "semantic-ui-react";
import { FunctionalFlowViewModel, FunctionalFlowYear } from "../../../services/FunctionalFlowService";

export interface IFlowMetricTableProps {
    functionalFlowViewModel: FunctionalFlowViewModel | null,
}

export const FlowMetricTable = (props: IFlowMetricTableProps) => {

    const filterFunctionData = useCallback((metricType: keyof (FunctionalFlowYear)):string | undefined => {
        if (!props.functionalFlowViewModel){
            return;
        }

        var values: number[] = props.functionalFlowViewModel.functionalFlowYears
            .map((year: FunctionalFlowYear) => year[metricType])
            .filter((x): x is number => x != null)
            .sort((a,b) => a-b);

        return `${getPercentile(values, 0.5).toFixed(2)} (${getPercentile(values, 0.1).toFixed(2)} - ${getPercentile(values, 0.9).toFixed(2)})`

    }, [props.functionalFlowViewModel])

    const getPercentile = (sortedArray: number[], quartile: number): number => {
        const pos = (sortedArray.length - 1) * quartile;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sortedArray[base + 1] !== undefined) {
            return sortedArray[base] + rest * (sortedArray[base + 1] - sortedArray[base]);
        } else {
            return sortedArray[base];
        }
    };
    return(
        <div className="sectionA-table">
            <Table structured>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Flow Component</Table.HeaderCell>
                        <Table.HeaderCell>Flow Metric</Table.HeaderCell>
                        <Table.HeaderCell>
                            <div>Natural Functional<br />Flow Metrics</div>
                            <div className="median">median (10th - 90th percentile)</div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {/* Fall Pulse Flow */}
                    <Table.Row>
                        <Table.Cell rowSpan="3" textAlign='center'>Fall Pulse Flow</Table.Cell>
                        <Table.Cell>Fall Pulse Magnitude (cfs)</Table.Cell>
                        <Table.Cell>{filterFunctionData("fallPulseMagnitude") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Fall Pulse Timing (water year day)</Table.Cell>
                        <Table.Cell>{filterFunctionData("fallPulseTiming") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Fall Pulse Duration (days)</Table.Cell>
                        <Table.Cell>{filterFunctionData("fallPulseDuration") ?? "" }</Table.Cell>
                    </Table.Row>
                    {/* Wet Season Baseflow */}
                    <Table.Row>
                        <Table.Cell rowSpan="4" textAlign='center'>Wet Season Baseflow</Table.Cell>
                        <Table.Cell>Wet-Season Baseflow magnitude (cfs)</Table.Cell>
                        <Table.Cell>{filterFunctionData("wetSeasonLowBaseflow") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Wet-Season Timing (water year day)</Table.Cell>
                        <Table.Cell>{filterFunctionData("wetSeasonTiming") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Wet-Season Duration (days)</Table.Cell>
                        <Table.Cell>{filterFunctionData("wetSeasonDuration") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Wet-Season Median Magnitude (cfs)</Table.Cell>
                        <Table.Cell>{filterFunctionData("wetSeasonMedianBaseflow") ?? "" }</Table.Cell>
                    </Table.Row>
                    {/* Peak Flows */}
                    <Table.Row>
                        <Table.Cell rowSpan="9" textAlign='center'>Peak Flows</Table.Cell>
                        <Table.Cell>2 year Peak Flow Magnitude (cfs)</Table.Cell>
                        <Table.Cell>{filterFunctionData("floodMagnitude2Year") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>2 year Peak Flow Duration (days)</Table.Cell>
                        <Table.Cell>{filterFunctionData("floodDuration2Year") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>2 year Peak Flow Frequency</Table.Cell>
                        <Table.Cell>{filterFunctionData("floodFrequency2Year") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>5 year Peak Flow Magnitude (cfs)</Table.Cell>
                        <Table.Cell>{filterFunctionData("floodMagnitude5Year") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>5 year Peak Flow Duration (days)</Table.Cell>
                        <Table.Cell>{filterFunctionData("floodDuration5Year") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>5 year Peak Flow Frequency</Table.Cell>
                        <Table.Cell>{filterFunctionData("floodFrequency5Year") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>10 year Peak Flow Magnitude (cfs)</Table.Cell>
                        <Table.Cell>{filterFunctionData("floodMagnitude10Year") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>10 year Peak Flow Duration (days)</Table.Cell>
                        <Table.Cell>{filterFunctionData("floodDuration10Year") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>10 year Peak Flow Frequency</Table.Cell>
                        <Table.Cell>{filterFunctionData("floodFrequency10Year") ?? "" }</Table.Cell>
                    </Table.Row>
                    {/* Spring Recession Flows */}
                    <Table.Row>
                        <Table.Cell rowSpan="4" textAlign='center'>Spring Recession Flows</Table.Cell>
                        <Table.Cell>Spring Recession Start Magnitude (cfs)</Table.Cell>
                        <Table.Cell>{filterFunctionData("springRecessionMagnitude") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Spring Timing (water day year)</Table.Cell>
                        <Table.Cell>{filterFunctionData("springTiming") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Spring Duration (days)</Table.Cell>
                        <Table.Cell>{filterFunctionData("springDuration") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Spring Rate of Change (percent)</Table.Cell>
                        <Table.Cell>{filterFunctionData("springRateOfChange") ?? "" }</Table.Cell>
                    </Table.Row>
                    {/* Dry-Season Baseflow */}
                    <Table.Row>
                        <Table.Cell rowSpan="4" textAlign='center'>Dry-Season Baseflow</Table.Cell>
                        <Table.Cell>Dry-Season Baseflow Magnitude</Table.Cell>
                        <Table.Cell>{filterFunctionData("fallPulseTiming") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Dry-Season Timing (water year day)</Table.Cell>
                        <Table.Cell>{filterFunctionData("drySeasonTiming") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Dry-Season Duration (days)</Table.Cell>
                        <Table.Cell>{filterFunctionData("drySeasonDuration") ?? "" }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Dry-Season High Magnitude (cfs)</Table.Cell>
                        <Table.Cell>{filterFunctionData("drySeasonMedianBaseflow") ?? "" }</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    )
};