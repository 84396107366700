import { Config, Data } from 'plotly.js';
import { useContext, useMemo } from 'react';
import Plot from 'react-plotly.js';
import { LookupsContext } from '../../App';
import { FlowMetric, Site } from '../../models/lookups';
import { FunctionalFlowMetricViewModel, FunctionalFlowYear } from '../../services/FunctionalFlowService';
import { IPlotLayoutProps } from './helpers/PlotLayoutProps';

export interface IAnnualFunctionalFlowMetricBoxPlotProps extends IPlotLayoutProps {
    site: Site,
    metric: FlowMetric,
    functionalFlowMetricViewModel: FunctionalFlowMetricViewModel,
    divRates: number[],
}

export const AnnualFunctionalFlowMetricBoxPlot = (props: IAnnualFunctionalFlowMetricBoxPlotProps) => {
    const [lookups] = useContext(LookupsContext);

    const config: Partial<Config> = {
        toImageButtonOptions: {
            format: 'png',
            filename: `Annual Functional Flow Box Plot - ${props.site.siteName} - ${props.metric.displayName}`,
            scale: 3
        },
        displaylogo: false,
    }

    const generateData: Data[] = useMemo(() => {
        const colors: Array<[number, string]> =
            [[0, 'rgb(255, 255, 204)'],
            [2, 'rgb(255, 245, 181)'],
            [4, 'rgb(255, 236, 157)'],
            [6, 'rgb(254, 225, 135)'],
            [8, 'rgb(254, 212, 112)'],
            [10, 'rgb(254, 191, 90)'],
            [12, 'rgb(254, 171, 73)'],
            [14, 'rgb(253, 151, 64)'],
            [16, 'rgb(253, 124, 55)'],
            [20, 'rgb(244, 61, 37)'],
            [25, 'rgb(202, 9, 35)'],
            [30, 'rgb(128, 0, 38)']
            ]
        let datas = new Array<Data>();
        props.divRates.forEach((divRate: number, i) => {
            datas.push({
                "type": "box",
                "name": `Div Rate ${divRate}%`,

                "y": props.functionalFlowMetricViewModel?.functionalFlowMetricYears.find((x) => x.diversionRate === divRate)!.functionalFlowYears
                    .map((year: FunctionalFlowYear) => year[props.metric.internalPropertyName.charAt(0).toLowerCase() + props.metric.internalPropertyName.slice(1) as keyof (FunctionalFlowYear)]),
                "marker": {
                    color: "rgb(90,90,90)"
                },
                fillcolor: colors[i][1]
            })
        });
        return datas;

    }, [props]);

    return (
        <>
            {lookups && props.functionalFlowMetricViewModel && props.metric &&
                <Plot
                    data={generateData}
                    layout={{
                        width: props.width,
                        height: props.height,
                        font: {
                            family: 'Arial,Helvetica',
                            size: 12,
                            color: 'black',
                        },
                        xaxis: {
                            title: `<b>Diversion Rate</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            ticks: 'inside',
                            mirror: true,
                            tickvals: Array.from(props.divRates.keys()),
                            ticktext: props.divRates.map((x) => x === 0 ? "Natural" : x + "%"),
                        },
                        yaxis: {
                            title: `<b>${props.metric.displayName} ${props.metric.unit}</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true,
                            hoverformat: '.2f',
                        },
                        plot_bgcolor: 'white',
                        paper_bgcolor: 'white',
                        hovermode: 'x',
                        showlegend: false,
                    }}
                    config={config}
                />

            }

        </>
    );

};

