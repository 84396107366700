import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "./index.scss";
import { AuthProvider } from "react-oidc-context";
import Config from "./config/Config";
import ReactGA from "react-ga4";

ReactGA.initialize("G-094J9YWVEV");

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <AuthProvider
        authority={Config.authInfo.server}
        client_id={Config.authInfo.clientId}
        redirect_uri={Config.authInfo.redirectUri}
        post_logout_redirect_uri={Config.authInfo.redirectUri}
        scope={Config.authInfo.scopes}
        onSigninCallback={() => {
            window.history.replaceState(
                {},
                document.title,
                window.location.pathname
            )
        }}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </AuthProvider>
);
