import Config from "../../config/Config";

const MapConfig = {
    basemapUrl: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
    copyright:
        "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community",
    wmsLayers: [
        {
            WMSLayerUrl: "https://gis.wramps.org/cgi-bin/qgis_mapserv.fcgi?MAP=/home/ubuntu/stateboard-cannabis/Web_GIS_Cannabis.qgs",
            WMSSublayerNames: [
                "SouthForkEelOutline",
                "Streams"
            ],
        },
    ],
    geoJSONLayers: [
        {
            geoJSONLayerUrl: Config.apiEndpoint + "/GeoJSON/sites",
            copyright: "",
            symbology: {
                type: "simple",
                symbol: {
                    type: "simple-marker",
                    size: 8,
                    color: [209, 62, 54],
                    outline: {  // autocasts as new SimpleLineSymbol()
                        width: 0.5,
                        color: [209, 62, 54],
                    }
                }
            }
        }
    ],
    zoom: 10,
    center: [-124, 40],
};

export default MapConfig;
