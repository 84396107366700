import { Config } from 'plotly.js';
import Plot from 'react-plotly.js';
import { Site } from '../../models/lookups';
import { FlowMatrixViewModel } from "../../services/FlowMatrixService";
import { IPlotLayoutProps } from './helpers/PlotLayoutProps';

export interface IAnnualStatisticalHeatmapProps extends IPlotLayoutProps {
    site: Site,
    flowMatrixViewModel: FlowMatrixViewModel,
}

export const AnnualStatisticalHeatmap = (props: IAnnualStatisticalHeatmapProps) => {

    const hovertemp = "<b> Day: %{x}<br> Water Year: %{y}<br> Daily Flow: %{z:.2f} cfs </b><extra></extra>"

    const config: Partial<Config> = {
        toImageButtonOptions: {
            format: 'png',
            filename: `Annual Statistical Heatmap - ${props.site.siteName}`,
            scale: 3
        },
        displaylogo: false,
    }

    return (
        <>
            {props &&
                <Plot
                    data={[{
                        'type': 'heatmap',
                        'x': Array.from({ length: props.flowMatrixViewModel.flowMatrixYears.length }, (_, i) => i + 1),
                        'y': Array.from({ length: props.flowMatrixViewModel.flowMatrixYears[0].flowMatrixDays.length }, (_, i) => i),
                        'z': props.flowMatrixViewModel.flowMatrixYears.map((year) => year.flowMatrixDays.map((day) => day.value)),
                        'colorscale': [[0, 'rgba(255,255,255,0.875)'],
                        [0.00390625, 'rgb(247,251,255)'],
                        [0.0078125, 'rgb(222,235,247)'],
                        [0.015625, 'rgb(198,219,239)'],
                        [0.03125, 'rgb(158,202,225)'],
                        [0.0625, 'rgb(107,174,214)'],
                        [0.125, 'rgb(66,146,198)'],
                        [0.25, 'rgb(33,113,181)'],
                        [0.5, 'rgb(8,81,156)'],
                        [1.0, 'rgb(8,48,107)']],
                        'colorbar': {
                            'title': 'Daily Flow',
                            'ticksuffix': ' cfs',
                            'ticks': 'outside',
                            'thickness': 10,
                            'outlinecolor': 'black',
                            'outlinewidth': 0.5,
                            'len': 1.75,
                        },
                        'xgap': 0.125,
                        'ygap': 0.125,
                        'hovertemplate': hovertemp,
                        'hoverlabel': {
                            font: {
                                color: "#267b9f",
                                size: 14,
                            },
                            bordercolor: '#333',
                            bgcolor: '#fff',
                        }
                    }]}

                    layout={{
                        width: props.width,
                        height: props.height,
                        font: {
                            family: 'Arial,Helvetica',
                            size: 10,
                            color: 'black',
                        },
                        xaxis: {
                            title: `<b>Day of Water Year</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true
                        },
                        yaxis: {
                            title: `<b>Water Year</b>`,
                            showline: true,
                            linewidth: 0.5,
                            linecolor: 'black',
                            gridcolor: 'lightgray',
                            zerolinecolor: 'lightgray',
                            mirror: true,
                        },
                        plot_bgcolor: 'rgba(0,0,0,0)', // transparent
                        paper_bgcolor: 'rgba(0,0,0,0)', // transparent
                        hovermode: 'closest',
                        modebar: {
                            'orientation': 'h'
                        }
                    }}
                    config={config}
                />
            }
        </>
    );
};