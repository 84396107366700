import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";

export interface FlowEcoRelationshipViewModel {
    flowMetricId: number,
    ecoIndicatorId: number,
    flowEcoData: FlowEcoPair[],
}

export interface FlowEcoPair {
    goodEcoProbability: number,
    metricValue: number
}


export class FlowEcoRelationshipService {
//    private authToken: string;

    // constructor(authToken: string) {
    //     this.authToken = authToken;
    // }

    async getFlowEcoRelationshipData(flowMetricId: number, ecoIndicatorId: number): Promise<FlowEcoRelationshipViewModel> {
        let config: AxiosRequestConfig = {
            headers: {
                //"Authorization": "Bearer " + this.authToken
            },
            params: {"flowMetricId": flowMetricId, "ecoIndicatorId": ecoIndicatorId}
        };
        const result = await axios.get<FlowEcoRelationshipViewModel>(Config.apiEndpoint + "/FlowEcoRelationship", config);
        return result.data;
    }

}
