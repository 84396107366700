import { useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "react-oidc-context";
import { DiversionYear } from "../../../models/DiversionYear";
import { EcoIndicator, FlowMetric, Site, TypeIIEcoIndicator } from "../../../models/lookups";
import { TypeIEcoAlterationRiskService } from "../../../services/TypeIEcoAlterationRiskService";
import { DropdownComponentWithFunction, IDropdownOption } from "../../dropdowns/Dropdown";
import { AnnualFunctionalFlowMetricBoxPlot } from "../../graphs/AnnualFunctionalFlowMetricBoxPlot";
import { AnnualFunctionalFlowMetricScatterPlot } from "../../graphs/AnnualFunctionalFlowMetricScatterPlot";
import { DiversionYearScatterPlot } from "../../graphs/DiversionYearScatterPlot";
import { IDataTabSectionProps } from "./SectionA";
import { useDimensions } from "./UseDimensions";
import { ButtonGroupComponent } from "../../buttonGroupComponent/ButtonGroupComponent";
import { FlowEcoRelationshipService, FlowEcoRelationshipViewModel } from "../../../services/FlowEcoRelationshipService";
import { DeltaMetricsService } from "../../../services/DeltaMetricsService";
import { FunctionalFlowService, FunctionalFlowMetricViewModel } from "../../../services/FunctionalFlowService";
import { FlowEcoRelationshipLineGraph } from "../../graphs/FlowEcoRelationshipLineGraph";
import { LookupsContext } from "../../../App";
import { TypeIIEcoAlterationRiskService } from "../../../services/TypeIIEcoAlterationRiskService";
import "./SectionC.scss";

export interface ISectionCProps extends IDataTabSectionProps {
    site: Site,
    metric: FlowMetric | null,
    ecoIndicator: EcoIndicator | undefined;
    selectedEcoIndicatorIdSectionB: number | string | null;
    typeIIEcoIndicator: TypeIIEcoIndicator | undefined;
}

export const SectionC = (props: ISectionCProps) => {
    let auth = useAuth();
    const [lookups] = useContext(LookupsContext);
    const [activeItem, setActiveItem] = useState<string>("Flow Metric");
    const deltaMetricsService = useMemo(() => new DeltaMetricsService(auth.user?.access_token ?? ""), [auth]);
    const [deltaMetricsData, setDeltaMetricsData] = useState<DiversionYear[]>([]);
    const [divRef, dimensions] = useDimensions();

    const chartWidth = useMemo(() => (dimensions?.width ?? 0) > 1200 ? (dimensions?.width ?? 0) / 2 : dimensions?.width ?? 0, [dimensions])

    const divRates = useMemo(() => deltaMetricsData
        .map(y => y.values.map(d => d.diversionRate))
        .flat()
        .filter((v, i, s) => s.indexOf(v) === i)
        .map(dr => parseFloat(dr)), [deltaMetricsData]);

    const functionalFlowService = useMemo(() => new FunctionalFlowService(), []);
    const [functionalFlowMetricViewModel, setFunctionalFlowMetricViewModel] = useState<FunctionalFlowMetricViewModel | null>(null);

    const typeIEcoAlterationRiskService = useMemo(() => new TypeIEcoAlterationRiskService(auth.user?.access_token ?? ""), [auth]);
    const [typeIEcoAlterationsRiskData, setTypeIEcoAlterationsRiskData] = useState<DiversionYear[]>([]);

    const typeIIEcoAlterationRiskService = useMemo(() => new TypeIIEcoAlterationRiskService(auth.user?.access_token ?? ""), [auth]);
    const [typeIIEcoAlterationsRiskData, setTypeIIEcoAlterationsRiskData] = useState<DiversionYear[] | undefined>(undefined);
    const [typeIIEcoAlterationsRiskDoesNotExist, setTypeIIEcoAlterationsRiskDoesNotExist] = useState<boolean>(false);

    const [selectedEcoIndicatorIdSectionC, setEcoIndicatorSelectedIdSectionC] = useState<number | null>(null);

    const ecoIndicator: EcoIndicator | undefined = useMemo(() => {
        if (!(lookups && selectedEcoIndicatorIdSectionC && selectedEcoIndicatorIdSectionC > 10000)) { return } // hacky way to distinguish between EcoIndicator and TypeIIEcoIndicator Ids
        return lookups.ecoIndicators.find(x => x.id === selectedEcoIndicatorIdSectionC - 10000)
    }, [selectedEcoIndicatorIdSectionC, lookups])

    const typeIIEcoIndicator: TypeIIEcoIndicator | undefined = useMemo(() => {
        if (props.typeIIEcoIndicator && (!selectedEcoIndicatorIdSectionC || !(selectedEcoIndicatorIdSectionC < 10000))) {
            // when B's dropdown is selected but C isnt, this will equal the B's Selection
            return props.typeIIEcoIndicator;
        }
        if (!(lookups && selectedEcoIndicatorIdSectionC && selectedEcoIndicatorIdSectionC < 10000)) { return }
        return lookups.typeIIEcoIndicators.find(x => x.id === selectedEcoIndicatorIdSectionC)
    }, [props.typeIIEcoIndicator, selectedEcoIndicatorIdSectionC, lookups])

    // get delta metrics data
    useEffect(() => {
        if (!(props.metric)) {
            return;
        }

        (async () => {
            setDeltaMetricsData(await deltaMetricsService.getDeltaMetrics(props.site.id, props.metric!.id));
        })().catch(console.log);

    }, [props.metric, props.site, deltaMetricsService]);

    // get type I eco alterations data
    useEffect(() => {
        if (!(props.metric && ecoIndicator)) {
            return;
        }

        (async () => {
            setTypeIEcoAlterationsRiskData(await typeIEcoAlterationRiskService.getTypeIEcoAlterationRisk(props.site.id, props.metric!.id, ecoIndicator.id));
        })().catch(console.log);
    }, [props.metric, props.site, typeIEcoAlterationRiskService, ecoIndicator]);

    // get type II eco alterations data
    useEffect(() => {
        setTypeIIEcoAlterationsRiskDoesNotExist(false);
        setTypeIIEcoAlterationsRiskData(undefined);

        if (!(typeIIEcoIndicator)) {
            return;
        }

        (async () => {
            try {
                setTypeIIEcoAlterationsRiskData(await typeIIEcoAlterationRiskService.getTypeIIEcoAlterationRisk(props.site.id, typeIIEcoIndicator.species.id, typeIIEcoIndicator.lifestage.id, typeIIEcoIndicator.ecoNeed.id));
                setTypeIIEcoAlterationsRiskDoesNotExist(false);
            }
            catch (response: any) {
                if (response && response.response.status === 404) {
                    setTypeIIEcoAlterationsRiskDoesNotExist(true);
                }
            }
        })()
    }, [props.site, typeIIEcoAlterationRiskService, typeIIEcoIndicator]);

    // get functional flow metric data
    useEffect(() => {
        if (!(props?.metric)) {
            return;
        }
        let fetchData = async () => {
            let data = await functionalFlowService.getFunctionalFlowMetricDataBySiteId(props?.site.id, props?.metric!.id);
            setFunctionalFlowMetricViewModel(data);
        }

        fetchData().catch(console.log);
    }, [functionalFlowService, props?.site, props?.metric])

    const flowEcoRelationshipService = useMemo(() => new FlowEcoRelationshipService(), []);
    const [flowEcoRelationshipViewModel, setFlowEcoRelationshipViewModel] = useState<FlowEcoRelationshipViewModel | null>(null);
    const [flowEcoRelationshipDoesNotExist, setFlowEcoRelationshipDoesNotExist] = useState<boolean>(false);

    //get flow eco relationship data
    useEffect(() => {
        if (!(props.metric && ecoIndicator)) {
            setFlowEcoRelationshipDoesNotExist(false);
            return;
        }

        setFlowEcoRelationshipViewModel(null);

        (async () => {
            try {
                let data = await flowEcoRelationshipService.getFlowEcoRelationshipData(props.metric!.id, ecoIndicator!.id);
                setFlowEcoRelationshipViewModel(data);
                setFlowEcoRelationshipDoesNotExist(false);
            }
            catch (response: any) {
                if (response.response.status === 404) {
                    setFlowEcoRelationshipDoesNotExist(true);
                }
            }
        })()
    }, [props.metric, flowEcoRelationshipService, ecoIndicator]);

    return (
        <div className="sectionC">
            <div className="sectionC-button-group">
                <ButtonGroupComponent
                    labels={["Flow Metric", "Type I", "Type II"]}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem} />
            </div>

            {/* Dropdown will only show in Type I & II not Flow Metric */}
            {lookups && activeItem !== "Flow Metric" &&
                <div className="select-eco-wrapper">
                    <div className="select-eco">
                        <DropdownComponentWithFunction
                            options={[...(activeItem === "Type II"
                                ? lookups.typeIIEcoIndicators.map(x => ({ key: x.id, text: `${x.species.displayName} ${x.lifestage.displayName} ${x.ecoNeed.displayName}`, value: x.id }) as IDropdownOption)
                                : lookups.ecoIndicators.map(x => ({ key: x.id + 10000, text: x.displayName, value: x.id + 10000 }) as IDropdownOption))]}
                            placeholder="Select Eco Indicator"
                            selected={(selectedEcoIndicatorIdSectionC ?? props.selectedEcoIndicatorIdSectionB) ?? undefined} // Default to section B's Selection
                            setSelected={setEcoIndicatorSelectedIdSectionC} />
                    </div>
                </div>
            }

            {activeItem === "Flow Metric" &&
                <div className="sectionC-chart-wrapper" ref={divRef} >
                    <div className="chart-wrapper">
                        <div className="chart-header">
                            Annual Functional Flow Metric Scatter Plot
                        </div>
                        <div className="chart">
                            {props?.metric && functionalFlowMetricViewModel && divRates.length > 0 &&
                                <AnnualFunctionalFlowMetricScatterPlot
                                    site={props.site}
                                    metric={props.metric}
                                    functionalFlowMetricViewModel={functionalFlowMetricViewModel}
                                    divRates={divRates}
                                    width={chartWidth}
                                    height={500}
                                />
                            }
                            {!(props?.metric && functionalFlowMetricViewModel && divRates.length > 0) &&
                                <div className="missing-chart-text-wrapper">
                                    <div className="missing-chart-text">Please select a metric above to show this graph
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="chart-wrapper">
                        <div className="chart-header">
                            Annual Functional Flow Metric Box Plot
                        </div>
                        <div className="chart">
                            {props?.metric && functionalFlowMetricViewModel && divRates.length > 0 &&
                                <AnnualFunctionalFlowMetricBoxPlot
                                    site={props.site}
                                    metric={props.metric}
                                    functionalFlowMetricViewModel={functionalFlowMetricViewModel}
                                    divRates={divRates}
                                    width={chartWidth}
                                    height={500}
                                />
                            }
                            {!(props?.metric && functionalFlowMetricViewModel && divRates.length > 0) &&
                                <div className="missing-chart-text-wrapper">
                                    <div className="missing-chart-text">Please select a metric above to show this graph
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="chart-wrapper">
                        <div className="chart-header">
                            Annual Delta Metrics Scatter Plot
                        </div>
                        <div className="chart">
                            {props?.metric && deltaMetricsData &&
                                <DiversionYearScatterPlot
                                    site={props.site}
                                    metric={props.metric}
                                    yAxisTitle={`Δ ${props.metric.displayName} (%)`}
                                    width={chartWidth}
                                    height={500}
                                    diversionYears={deltaMetricsData} />
                            }
                            {!(props?.metric && deltaMetricsData && deltaMetricsData.length > 0) &&
                                <div className="missing-chart-text-wrapper">
                                    <div className="missing-chart-text">Please select a metric above to show this graph</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            {activeItem === "Type I" &&
                <div className="sectionC-chart-wrapper">
                    <div className="chart-wrapper">
                        <div className="chart-header">
                            Flow Eco Indicator Relationship
                        </div>
                        <div className="chart">
                            {props.metric && flowEcoRelationshipViewModel && ecoIndicator &&
                                <FlowEcoRelationshipLineGraph
                                    flowEcoRelationshipViewModel={flowEcoRelationshipViewModel}
                                    flowMetric={props.metric}
                                    ecoIndicator={ecoIndicator}
                                    width={chartWidth}
                                    height={500} />
                            }
                            {!(props.metric && flowEcoRelationshipViewModel && ecoIndicator && lookups) && !flowEcoRelationshipDoesNotExist &&
                                <div className="missing-chart-text-wrapper">
                                    <div className="missing-chart-text">Please select a metric and Type I Eco Indicator above to show this graph.</div>
                                    <div className="missing-chart-text">*** Note that not every combo of eco indicator and metric has data!</div>
                                </div>
                            }
                            {flowEcoRelationshipDoesNotExist &&
                                <div className="missing-chart-text-wrapper">
                                    <div className="missing-chart-text">A Flow Eco Relationship does not exist for this combination</div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="chart-wrapper">
                        <div className="chart-header" style={{ zIndex: 999, position: "relative" }}>
                            ASCI/CSCI vs Diversion Scatter
                        </div>
                        <div className="chart">
                            {props.metric && typeIEcoAlterationsRiskData && ecoIndicator &&
                                <DiversionYearScatterPlot
                                    site={props.site}
                                    metric={props.metric}
                                    yAxisTitle={`Probability of Good ${ecoIndicator.displayName}`}
                                    width={chartWidth}
                                    height={500}
                                    diversionYears={typeIEcoAlterationsRiskData} />
                            }
                            {!(props.metric && ecoIndicator && typeIEcoAlterationsRiskData && typeIEcoAlterationsRiskData.length > 0) &&
                                <div className="missing-chart-text-wrapper">
                                    <div className="missing-chart-text">Please select a metric and eco indicator above to show this graph</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {activeItem === "Type II" &&
                <div className="sectionC-chart-wrapper">
                    <div className="chart-wrapper">
                        <div className="chart-wrapper">
                            <div className="chart-header">
                                Type II Eco Alteration Risk
                            </div>
                            <div className="chart">
                                {props.metric && typeIIEcoAlterationsRiskData && typeIIEcoIndicator && !typeIIEcoAlterationsRiskDoesNotExist &&
                                    <DiversionYearScatterPlot
                                        site={props.site}
                                        metric={props.metric}
                                        yAxisTitle={typeIIEcoIndicator && typeIIEcoIndicator.ecoNeed.internalName === "passage"
                                            ? `Number of Days Passing For Each<br>Water Year Relative to Natural (%)`
                                            : `Number of Days Above 80% WUA For<br>Each Water Year Relative to Natural (%)`}
                                        width={chartWidth}
                                        height={500}
                                        diversionYears={typeIIEcoAlterationsRiskData} />
                                }
                                {!(props.metric && typeIIEcoIndicator && typeIIEcoAlterationsRiskData) && !typeIIEcoAlterationsRiskDoesNotExist &&
                                    <div className="missing-chart-text-wrapper">
                                        <div className="missing-chart-text">Please select a Type II Eco Indicator above to show this graph</div>
                                    </div>
                                }
                                {typeIIEcoAlterationsRiskDoesNotExist &&
                                    <div className="missing-chart-text-wrapper">
                                        <div className="missing-chart-text">A Type II Eco Alteration Risk does not exist for this combination</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
