import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";

export interface RiffleDepthViewModel {
    riffleDepthValuesData: RiffleDepthValues[];
    distances: number[];
    thalwegs: number[];
    thresholdDistanceAndFlow: ThresholdDistance[];
}

export interface RiffleDepthValues {
    columnName: string;
    depthValues: number[];
}

export interface ThresholdDistance {
    distance: number;
    flowCfs: number;
}

export class RiffleDepthService {
//    private authToken: string;

    // constructor(authToken: string) {
    //     this.authToken = authToken;
    // }

    async getRiffleDepthData(siteId: number, speciesId: number, lifestageId: number): Promise<RiffleDepthViewModel> {
        let config: AxiosRequestConfig = {
            headers: {
                //"Authorization": "Bearer " + this.authToken
            },
            params: {"siteId": siteId, "speciesId": speciesId, "lifestageId": lifestageId}
        };
        const result = await axios.get<RiffleDepthViewModel>(Config.apiEndpoint + "/RiffleDepth", config);
        return result.data;
    }

}
