import "./Summary.scss";

export interface ISummaryProps {
    mySampleOne?: number;
    mySampleTwo?: string;
}

export const Summary = (props: ISummaryProps) => {

    return (
        <div className="summary">
            <p>Sweet roll cupcake chocolate chupa chups toffee ice cream chocolate liquorice. Halvah bonbon apple pie cupcake tootsie roll cupcake caramels tootsie roll. Soufflé chocolate cake macaroon wafer dessert. Tiramisu dessert tiramisu chupa chups tootsie roll.</p>
            <p>Tart wafer halvah cake jujubes topping tootsie roll brownie. Biscuit liquorice cake oat cake sweet shortbread tiramisu sugar plum marshmallow. Ice cream cake tiramisu marzipan sugar plum topping tart dessert.</p>
            <p>Candy canes jelly beans tart icing brownie powder croissant dragée chocolate. Dessert bonbon tootsie roll chocolate bear claw croissant lollipop caramels jelly. Jelly dessert halvah sweet roll marshmallow cupcake toffee powder jelly. Bear claw macaroon tiramisu jelly carrot cake.</p>
            <p>Icing tootsie roll oat cake candy cheesecake wafer sweet roll fruitcake. Tiramisu apple pie dessert topping bonbon lemon drops caramels jelly beans. Wafer gummi bears sweet marshmallow chocolate bar chocolate wafer bonbon cookie.</p>
            <p>Bear claw carrot cake cheesecake lemon drops lemon drops tart biscuit jelly-o. Cheesecake fruitcake chupa chups toffee wafer halvah lemon drops gummies halvah. Gummies marshmallow wafer candy canes tootsie roll dragée muffin.</p>
        </div>
    );
};